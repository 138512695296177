import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { phoneMask } from "@shared/masks/phone.mask";
import { RecommendationHttpService } from "../../services/recommendation-http.service";
import { HttpErrorResponse } from "@angular/common/http";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { faImage, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { NewImplementationHttpService } from "../../../implementation/services/new-implementation-http.service";
import { transformErrorHelper } from "@shared/helpers/transform-error.helper";

@Component({
  selector: 'app-recommendation-details',
  templateUrl: './recommendation-details.component.html',
  styleUrls: ['./recommendation-details.component.scss']
})
export class RecommendationDetailsComponent implements OnInit {
  @ViewChild('fileUpload')
  fileInput!: ElementRef<HTMLInputElement>;

  addImageIcon: IconDefinition = faImage;
  removeImageIcon: IconDefinition = faTimes;

  form: FormGroup;
  recId: number;
  recImages: Array<{
    realization_image_id: number,
    url: string,
  }> = [];
  newImages: any[] = [];

  isLoading: boolean = true;
  phoneNumberInputMask = phoneMask;
  errorMessage: string | undefined;
  newImagesFormArr: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private recommendationHttpService: RecommendationHttpService,
    private newImplementationHttpService: NewImplementationHttpService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.subscribeRoutingParams();
  }

  subscribeRoutingParams() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.hasOwnProperty('id')) {
        this.recId = params['id'];
        this.clearForm();
        this.getDetails();
      }
    });
  }

  clearForm() {
    this.form = new FormGroup({
      phone_number: new FormControl(null),
      email: new FormControl(null, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    }, {
      validators: this.checkFieldsValidator
    });
  }

  checkFieldsValidator(control : AbstractControl) : ValidationErrors | null {
    if (!control.get('phone_number')?.value && !control.get('email')?.value) {
      return { invalidFields : true };
    }

    return null;
  }

  getDetails() {
    this.newImplementationHttpService.getDraft(this.recId)
      .subscribe(d => {
        this.recImages = d.images;
        this.isLoading = false;
      });
  }

  onSubmit() {
    this.isLoading = true;

    const data: FormData | any = new FormData();

    this.newImagesFormArr.forEach(i => {
      data.append("new_images[]", i);
    })

    if (this.recImages.length) {
      this.recImages.forEach(i => {
        data.append("realization_image_ids[]", i.realization_image_id.toString());
      });
    }

    const email = this.form.value.email === null ? "" : this.form.value.email;
    const phone = this.form.value.phone_number === null ? "" : this.form.value.phone_number;

    data.append("email", email);
    data.append("phone_number", phone);

    this.recommendationHttpService.sendOpinionRequest(this.recId, data)
      .subscribe({
        next: () => {
          this.router.navigate(
            [`recommendation/details/${this.recId}/send`],
          );
          this.isLoading = false;
        },
        error: (err: HttpErrorResponse) => {
          this.errorMessage = transformErrorHelper(err);
          this.isLoading = false;
        }
      });
  }

  onFileSelected(event: any): void {
    if (!event.target.files[0] || (event.target.files[0] && event.target.files[0].length < 2)) {
      this.errorMessage = 'Musisz przesłać minimum 2 zdjęcia';
      return;
    }

    let mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.errorMessage = "Plik musi być obrazem";
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.errorMessage = '';
      this.newImages.push(reader.result);
      this.newImagesFormArr.push(event.target.files[0]);
    }
  }

  removePhoto(idx: number, newImages?: boolean): void {
    if (newImages) {
      this.newImages.splice(idx, 1);
      this.newImagesFormArr.splice(idx, 1);
      return;
    }

    this.recImages.splice(idx, 1);
  }
}
