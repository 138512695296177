import { Component } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-recommendation-send',
  templateUrl: './recommendation-send.component.html',
  styleUrls: ['./recommendation-send.component.scss']
})
export class RecommendationSendComponent {

  constructor(
    private router: Router,
  ) { }

  back() {
    this.router.navigate(['recommendation']);
  }
}
