import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingsRoutingModule } from './trainings-routing.module';
import { SharedModule } from '@shared/shared.module';
import { TrainingsComponent } from './components/trainings/trainings.component';
import { TrainingsListAllComponent } from './components/trainings-list-all/trainings-list-all.component';
import { TrainingsListUserComponent } from './components/trainings-list-user/trainings-list-user.component';

@NgModule({
    declarations: [
        TrainingsComponent,
        TrainingsListAllComponent,
        TrainingsListUserComponent,
    ],
    imports: [CommonModule, TrainingsRoutingModule, SharedModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TrainingsModule {}
