<app-previous-page-navigator
  [title]="'Moje dane superwykonawcy'"
  [prevRoute]="['/', 'recommendation', 'profile']"
>
</app-previous-page-navigator>

<div
  [appFullHeightPage]="{hasNavigation: false}"
  class="container sp-con-combo"
  [appLoading]="isLoading"
>
  <section class="profile-edit" *ngIf="!isLoading && performer">
    <form [formGroup]="form" *ngIf="form">
      <app-form-control
        [label]="'Ulica'"
        [id]="'street'"
        [controlName]="'street'"
        [form]="form"
      ></app-form-control>
      <app-form-control
        [label]="'Numer budynku'"
        [id]="'house_number'"
        [controlName]="'house_number'"
        [form]="form"
      ></app-form-control>
      <app-form-control
        [label]="'Numer lokalu'"
        [id]="'local_number'"
        [controlName]="'local_number'"
        [form]="form"
      ></app-form-control>
      <app-form-control
        [label]="'Kod pocztowy'"
        [id]="'post_code'"
        [controlName]="'post_code'"
        [inputInputMask]="postCodeMask"
        [form]="form"
      ></app-form-control>
      <app-form-control
        [label]="'Miejscowość'"
        [id]="'city'"
        [controlName]="'city'"
        [form]="form"
      ></app-form-control>
      <app-form-control
        [controlName]="'phone_number'"
        [form]="form"
        [id]="'phone_number'"
        [inputInputMask]="phoneNumberInputMask"
        [label]="'Numer telefonu'"
        [placeholder]="'000-000-000'"
        [type]="'tel'"
      ></app-form-control>
      <app-form-control
        [label]="'Email'"
        [id]="'email'"
        [controlName]="'email'"
        [form]="form"
      ></app-form-control>
    </form>

    <p class="text-errors fs-14 lh-16" *ngIf="form?.hasError('invalidFields')" [innerHTML]="invalidMsg"></p>
    <p class="text-errors fs-14 lh-16" *ngIf="errorMessage" [innerHTML]="errorMessage"></p>

    <div class="box">
      <button
        class="btn btn-primary btn-lg"
        type="submit"
        (click)="onSubmit()"
        [disabled]="form.invalid"
      >
        Zapisz
      </button>
    </div>
  </section>
</div>
