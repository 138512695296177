import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttpService } from '@services/http/api-http.service';
import {
  Certificate, CertificateSignedDownloadUrl,
  PasswordChangeModel,
  UpdateUserModel,
  UserModel,
} from '@shared/models/user.model';
import {
    ImplementationDetailsModel,
    ImplementationModel,
} from '@shared/models/implementation.model';

@Injectable({
    providedIn: 'root',
})
export class ProfileHttpService {
    constructor(private readonly apiService: ApiHttpService) {}

    public getProfile(): Observable<UserModel> {
        return this.apiService.get('user/me');
    }

    public updateProfile(payload: UpdateUserModel): Observable<UserModel> {
        return this.apiService.patch('user/me', payload);
    }

    public uploadAvatar(payload: FormData): Observable<void> {
        return this.apiService.post('user/upload-avatar', payload);
    }

    public updatePassword(payload: PasswordChangeModel): Observable<void> {
        return this.apiService.patch('user/password', payload);
    }

    public getCertificates(): Observable<Array<Certificate>> {
        return this.apiService.get('performer-certificate');
    }

    public getSignedDownloadUrl(
        absoluteUrl: string,
    ): Observable<CertificateSignedDownloadUrl> {
        return this.apiService.get(absoluteUrl);
    }

    public getImplementations(
        page: string,
    ): Observable<Array<ImplementationModel>> {
        return this.apiService.get(`realization/${page}`);
    }

    public getImplementationDetails(
        id: string,
    ): Observable<ImplementationDetailsModel> {
        return this.apiService.get(`realization/realization_id/${id}`);
    }
}
