<app-previous-page-navigator
    [prevRoute]="'../'"
    [title]="'Wiadomości'"></app-previous-page-navigator>
<div class="content">
  <form>
    <div class="label">Temat</div>
    <div class="text-response">
      <input [(ngModel)]="title" type="text" name="title" type="text">
    </div>
    <div class="label">Wiadomości</div>
    <div class="text-response">
      <input [(ngModel)]="message" type="text" name="message" type="text">
    </div>
  </form>
  <div class="send-button" (click)="newThread()">
    Wyślij
  </div>
</div>

