import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    faAngleRight,
    faChevronLeft,
    faChevronRight,
    faChevronsLeft,
    faChevronsRight,
} from '@fortawesome/pro-solid-svg-icons';
import { trainingList } from '../../mocks/trainings.mock';
import { training } from '../../interfaces/training.interface';
import {
    lastValueFrom,
    map,
    of,
    startWith,
    Subscription,
    take,
    tap,
} from 'rxjs';
import { ProfileHttpService } from '../../../profile/services/profile-http.service';
import { TrainingsHttpService } from '../../services/trainings-http.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-trainings-list-all',
    templateUrl: './trainings-list-all.component.html',
    styleUrls: ['./trainings-list-all.component.scss'],
})
export class TrainingsListAllComponent implements OnInit, OnDestroy {
    protected readonly faAngleRight = faAngleRight;
    protected readonly faChevronsLeft = faChevronsLeft;
    protected readonly faChevronLeft = faChevronLeft;
    protected readonly faChevronRight = faChevronRight;
    protected readonly faChevronsRight = faChevronsRight;

    protected trainingList: training[] = [];
    pageNumber = 1;
    maxPageNumber = 1;
    subs: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private trainingService: TrainingsHttpService,
    ) {}

    ngOnInit() {
        this.subscribe();
    }
    ngOnDestroy() {
        this.unsubscribe();
    }

    subscribe() {
        this.subs.push(
            this.route.paramMap
                .pipe(
                    tap(this.getPagesFromPatrameters.bind(this)),
                    tap(this.fetchAndComputeData.bind(this)),
                )
                .subscribe(),
        );
    }

    unsubscribe() {
        this.subs.forEach(ele => {
            ele.unsubscribe();
        });
    }

    fetchAndComputeData() {
        lastValueFrom(of(1))
            .then(() =>
                lastValueFrom(
                    this.trainingService
                        .getTrainingsListPage(this.pageNumber)
                        .pipe(
                            // startWith(trainingList), // MOCKED DATA!!!!!
                            take(1),
                            tap(this.extractMaxPage.bind(this)),
                            map(this.retriveDay.bind(this)),
                            map(this.checkTrainingIsPast.bind(this)),
                            map(this.assignTrainings.bind(this)),
                        ),
                ),
            )
            .then(() => {});
    }

    extractMaxPage(response: HttpResponse<training[]>) {
        const items = parseInt(
            response.headers.get('X-Pagination-Items-Count') as string,
        );
        const perpage = parseInt(
            response.headers.get('X-Pagination-Per-Page') as string,
        );
        this.maxPageNumber = Math.ceil(items / perpage);
    }

    getPagesFromPatrameters(params: ParamMap) {
        this.pageNumber = parseInt(params.get('page') as string);
        if (!this.pageNumber) {
            this.pageNumber = 1;
            this.router.navigate(['./', { page: 1 }], {
                relativeTo: this.route,
            });
        }
    }

    retriveDay(response: HttpResponse<training[]>) {
        (response.body as training[]).forEach(training => {
            const trainingDate = new Date(training.date);
            const year = trainingDate.getFullYear();
            const month = trainingDate.getMonth() + 1;
            const formattedMonth = month < 10 ? `0${month}` : month;
            const day = trainingDate.getDate();
            const formattedDay = day < 10 ? `0${day}` : day;
            const dateOnly = `${year}-${formattedMonth}-${formattedDay}`;
            training.date = dateOnly;
        });
        return response;
    }

    checkTrainingIsPast(response: HttpResponse<training[]>) {
        (response.body as training[]).forEach(training => {
            const trainingDate = new Date(training.date);
            const currentDate = new Date();
            if (
                trainingDate.getFullYear() === currentDate.getFullYear() &&
                trainingDate.getMonth() === currentDate.getMonth() &&
                trainingDate.getDay() === currentDate.getDay()
            ) {
                training.is_past = false;
            } else {
                training.is_past = currentDate > trainingDate;
            }
        });
        return response;
    }

    assignTrainings(response: HttpResponse<training[]>) {
        this.trainingList = response.body as training[];
        return response;
    }

    signUpTraining(training: training) {
        this.trainingService.patchSignUpForTraining(training.id).subscribe({
            next: () => this.navigateToTrainings(),
            error: () => this.fetchAndComputeData(),
            complete: () => this.fetchAndComputeData(),
        });
    }
    nextPage() {
        if (this.pageNumber + 1 <= this.maxPageNumber) {
            this.router.navigate(['./', { page: this.pageNumber + 1 }], {
                relativeTo: this.route,
            });
        }
    }
    prevPage() {
        if (this.pageNumber - 1 >= 1) {
            this.router.navigate(['./', { page: this.pageNumber - 1 }], {
                relativeTo: this.route,
            });
        }
    }
    lastPage() {
        this.router.navigate(['./', { page: this.maxPageNumber }], {
            relativeTo: this.route,
        });
    }
    firstPage() {
        this.router.navigate(['./', { page: 1 }], {
            relativeTo: this.route,
        });
    }
    navigateToTrainings() {
        this.router.navigate(['../wykonawca'], {
            relativeTo: this.route,
        });
    }
    redirectToTrainingUrl(training: training) {
        if (training?.url) {
            window.open(training.url, '_blank');
        }
    }
}
