import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttpService } from '@services/http/api-http.service';
import { knowledgeBaseCourse } from '../interfaces/knowledge-base-list.interface';
import {HttpResponse} from "@angular/common/http";

@Injectable({
    providedIn: 'root',
})
export class KnowledgeBaseHttpService {
    constructor(private readonly apiService: ApiHttpService) {}

    public getKnowledgeBaseList(): Observable<knowledgeBaseCourse[]> {
        return this.apiService.get('knowledge-base/list');
    }
    public patchKnowledgeBaseAddFavourite(
        id: number,
    ): Observable<knowledgeBaseCourse[]> {
        return this.apiService.patch(`knowledge-base/add-favourite/${id}`);
    }
    public patchKnowledgeBaseRemoveFavourite(
        id: number,
    ): Observable<knowledgeBaseCourse[]> {
        return this.apiService.patch(`knowledge-base/remove-favourite/${id}`);
    }
    public getKnowledgeBaseFile(
        id: number,
    ): Observable<HttpResponse<Blob>> {
        return this.apiService.getBlob(`knowledge-base/download/${id}`);
    }
}
