import { StoragePersisterInterface } from '@services/storages/persisters/storage-persister.interface';

export class StoragePersisterCookie implements StoragePersisterInterface {
    constructor(private readonly path: string = '/main') {}

    public hasData(key: string): boolean {
        return !!this.getCookie(key);
    }

    public getData(key: string): string {
        const cookie = this.getCookie(key);

        if ('' === cookie) {
            throw new Error('key does not exists');
        }

        return cookie;
    }

    public saveData(key: string, value: string): void {
        if (this.hasData(key)) {
            throw new Error('key already exists');
        }

        this.setCookie(key, value);
    }

    public removeData(key: string): void {
        if (!this.hasData(key)) {
            throw new Error('key does not exists');
        }

        this.setCookie(key, '');
    }

    protected getCookie(key: string): string {
        return document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=');

            return parts[0] === key ? decodeURIComponent(parts[1]) : r;
        }, '');
    }

    protected setCookie(key: string, value: string): void {
        const secure = window.location.protocol === 'https';
        const cookieValue = `${key}=${encodeURIComponent(
            value,
        )}; expires=${this.expires.toUTCString()}; path=${
            this.path
        }; SameSite=LAX ${secure ? '; Secure' : ''}`;

        document.cookie = cookieValue;
    }

    private get expires(): Date {
        return new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365);
    }
}
