import { BasketItemInterface } from '../interfaces/basket.interface';

export abstract class BasketStorage {
    public abstract set(items: BasketItemInterface[]): void;
    public abstract put(item: BasketItemInterface): BasketItemInterface;
    public abstract remove(item: BasketItemInterface): void;
    public abstract all(): BasketItemInterface[];
    public abstract clear(): void;
    public abstract count(): number;
}
