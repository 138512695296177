<ng-container [formGroup]="form">
    <label class="fluid">
        <textarea
                class="form-control"
                oninput="this.parentNode.dataset.value = this.value"
                [id]="id"
                [placeholder]="placeholder ?? ''"
                [formControlName]="controlName"
                [rows]="rows"
        ></textarea>
    </label>
</ng-container>
