<app-previous-page-navigator
  [prevRoute]="undefined"
  [title]="'Rekomendacje'"
></app-previous-page-navigator>

<div [appFullHeightPage]="{hasNavigation: false}" class="container sp-con-combo">
  <section class="recommendation-send">
      <div class="description">
        Prośba o opinię została wysłana!

        <br><br>

        Po wystawieniu opinii przez Inwestora będzie ona widoczna na Twoim profilu Wykonawcy na stronie superwykonawca.pl
      </div>

      <button
        (click)="back()"
        class="btn btn-primary btn-lg submit"
      >
        OK
      </button>
  </section>
</div>
