import { Component, OnInit } from '@angular/core';
import { messagesInThread } from '../../mocks/messagesInThread.mock';
import { ActivatedRoute, Router } from '@angular/router';
import { faReply } from '@fortawesome/pro-solid-svg-icons';
import { map } from 'rxjs';
import { MessagesHttpService } from '../../services/messages-http.service';
import { messageThread } from '../../interfaces/thread.interface';
import { MessagesInThread } from '../../interfaces/messageInThread.interface';

@Component({
    selector: 'app-messages-thread',
    templateUrl: './messages-thread.component.html',
    styleUrls: ['./messages-thread.component.scss'],
})
export class MessagesThread implements OnInit {
    threadId = 0;
    messagesInThread: MessagesInThread | undefined;
    protected readonly faReply = faReply;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private messagesHttpService: MessagesHttpService,
    ) {}

    ngOnInit() {
        this.threadId = parseInt(
            this.route.snapshot.paramMap.get('threadId') as string,
        );
        this.fetchAndComputeData();
    }
    fetchAndComputeData() {
        this.messagesHttpService
            .getThreadWithMessages(this.threadId)
            .pipe(
                map(this.sortMessagesByDate.bind(this)),
                map(this.extractDate.bind(this)),
            )
            .subscribe(messagesInThread => {
                this.messagesInThread = messagesInThread;
            });
    }
    sortMessagesByDate(thread: MessagesInThread) {
        thread.messages.sort((message1, message2) => {
            const mes1Date = new Date(message1.created_at);
            const mes2Date = new Date(message2.created_at);
            if (mes1Date > mes2Date) return 1;
            if (mes1Date === mes2Date) return 0;
            if (mes1Date < mes2Date) return -1;
            return 0;
        });
        return thread;
    }

    extractDate(thread: MessagesInThread) {
        thread.messages.forEach(message => {
            const date = new Date(message.created_at);
            const year = date.getFullYear();
            // getMonth() returns a 0-based index, so add 1 to get the correct month number
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            message.created_at = `${day}-${month}-${year}`;
        });
        return thread;
    }
    navigateToResponse(id: number) {
        this.router.navigate(['response'], { relativeTo: this.route });
    }
}
