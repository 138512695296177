import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MessagesThreadsList } from './components/messages-threads-list/messages-threads-list.component';
import { MessagesThread } from './components/messages-thread/messages-thread.component';
import { MessageReply } from './components/message-reply/message-reply.component';
import { MessageNew } from './components/message-new/message-new.component';

const routes: Routes = [
    {
        path: '',
        component: MessagesThreadsList,
    },
    {
        path: 'nowa',
        component: MessageNew,
    },
    {
        path: ':threadId',
        component: MessagesThread,
    },
    {
        path: ':threadId/response',
        component: MessageReply,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MessagesRoutingModule {}
