<ng-container [formGroup]="form">
    <input class="form-control"
            [type]="type"
            [id]="id"
            [name]="name"
            [placeholder]="placeholder ?? ''"
            [inputMask]="inputInputMask"
            [attr.inputmode]="inputMode ? inputMode : (['tel'].includes(type) ? type : ('number' === type ? 'numeric' : ''))"
            [formControlName]="controlName"
            [readOnly]="isReadonly"
    />
</ng-container>
