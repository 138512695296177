import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TrainingsComponent } from './components/trainings/trainings.component';
import { TrainingsListAllComponent } from './components/trainings-list-all/trainings-list-all.component';
import { TrainingsListUserComponent } from './components/trainings-list-user/trainings-list-user.component';

const routes: Routes = [
    {
        path: '',
        component: TrainingsComponent,
    },
    {
        path: 'wszystkie',
        component: TrainingsListAllComponent,
    },
    {
        path: 'wykonawca',
        component: TrainingsListUserComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TrainingsRoutingModule {}
