import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { RecommendationHttpService } from "../../services/recommendation-http.service";
import { ProfileModel, RecommendationModel } from "../../models/performer-profile.model";
import {
  faChevronRight,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { ProfileHttpService } from "../../../profile/services/profile-http.service";
import { UserModel } from "@shared/models/user.model";
import { getRecommendationStatus } from "../../helpers/recommendation-status.helper";
import { RecommendationStatus } from "../../models/recommendation.model";

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss']
})
export class RecommendationComponent implements OnInit {
  isLoading: boolean;
  iconNext: IconDefinition = faChevronRight;

  recommendations: Array<RecommendationModel> = [];
  user: UserModel;
  performer: ProfileModel | null;

  disableRecs: boolean;

  constructor(
    private router: Router,
    private profileHttpService: ProfileHttpService,
    private recommendationHttpService: RecommendationHttpService,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.profileHttpService.getProfile()
      .subscribe((r) => {
        this.user = r
        this.handleAccess();
      });
  }

  subscribePerformer() {
    this.recommendationHttpService.getPerformerRating()
      .subscribe((performer) => {
        this.performer = performer;
        this.disableRecs = this.checkRecAccess();
        this.getRecommendations();
      })
  }

  checkRecAccess() {
    if (!this.performer?.email && !this.performer?.phone_number) {
      return true;
    }

    if (this.performer?.email && this.performer.phone_number) {
      return this.performer.email_pending && this.performer?.phone_pending
    }

    if (this.performer?.email && !this.performer?.phone_number) {
      return this.performer.email_pending;
    }

    if (!this.performer?.email && this.performer?.phone_number) {
      return this.performer.phone_pending;
    }

    return false;
  }

  handleAccess() {
    if (!this.user.has_performer_profile) {
      this.router.navigate(['recommendation', 'profile']);
      return;
    }

    this.subscribePerformer();
  }

  private getRecommendations(): void {
    this.recommendationHttpService.getRealizations()
      .subscribe({
        next: (resp) => {
          const clonedResp = [...resp];
          this.handleStatus(clonedResp);
          this.recommendations = clonedResp;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      })
  }

  handleStatus(arr: RecommendationModel[]) {
    arr.forEach((r) => {
      r.statusColor = getRecommendationStatus(r.status);
    });

    this.sortRecommendations(arr);
  }

  sortRecommendations(arr: RecommendationModel[]) {
    arr.forEach((r) => {
      switch (r.status) {
        case RecommendationStatus.REQUEST:
          r.statusSort = 1;
          break;
        case RecommendationStatus.PENDING:
          r.statusSort = 2;
          break;
        case RecommendationStatus.ACCEPTED:
          r.statusSort = 3;
          break;
      }
    });

    arr.sort((a, b) => {
      if (a.statusSort && b.statusSort) {
        return a.statusSort - b.statusSort || <any>new Date(b.date) - <any>new Date(a.date);
      } else {
        return <any>new Date(b.date) - <any>new Date(a.date);
      }
    });
  }

  seeDetails(recommendation: RecommendationModel) {
    if (recommendation.status == RecommendationStatus.REQUEST && !this.disableRecs) {
      this.router.navigate(
        [`recommendation/details/${recommendation.realization_id}`],
      );
    }
  }

  protected readonly RecommendationStatus = RecommendationStatus;
}
