import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ProfileModel, UpdateProfileModel } from "../../models/performer-profile.model";
import { InputmaskOptions } from "@ngneat/input-mask";
import { phoneMask } from "@shared/masks/phone.mask";
import { postCodeMask } from '@shared/masks/post-code.mask';
import { RecommendationHttpService } from "../../services/recommendation-http.service";
import { Router } from "@angular/router";
import { transformErrorHelper } from "@shared/helpers/transform-error.helper";

@Component({
  selector: 'app-recommendation-profile-edit',
  templateUrl: './recommendation-profile-edit.component.html',
  styleUrls: ['./recommendation-profile-edit.component.scss']
})
export class RecommendationProfileEditComponent implements OnInit {
  isLoading = true;
  performer!: ProfileModel | null;
  form: FormGroup;
  postCodeMask: InputmaskOptions<string> = postCodeMask;
  phoneNumberInputMask: InputmaskOptions<string> = phoneMask;
  errorMessage: string | undefined | null;
  invalidMsg: string | undefined | null;

  constructor(
    private formBuilder: FormBuilder,
    private recommendationHttpService: RecommendationHttpService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.subscribePerformer();
    this.clearForm();
  }

  subscribePerformer() {
    this.recommendationHttpService.getPerformerRating()
      .subscribe((performer) => {
        this.performer = performer;
        this.setFormData(performer);
      })
  }

  clearForm() {
    this.form = this.formBuilder.group({
      city: this.formBuilder.control(null, [Validators.required]),
      street: this.formBuilder.control(null, [Validators.required]),
      local_number: this.formBuilder.control(null),
      house_number: this.formBuilder.control(null, [Validators.required]),
      post_code: this.formBuilder.control(null, [Validators.required]),
      email: this.formBuilder.control(null, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      phone_number: this.formBuilder.control(null, []),
    }, {
      validators: this.checkFieldsValidator
    });

    this.subscribeFormValueChanges();
  }

  checkFieldsValidator(control : AbstractControl) : ValidationErrors | null {
    if (!control.get('phone_number')?.value && !control.get('email')?.value) {
      return { invalidFields : true };
    }

    return null;
  }

  subscribeFormValueChanges() {
    this.form.valueChanges.subscribe(()  => {
      if (this.form.hasError('invalidFields')) {
        this.invalidMsg = "Conajmniej jedna, zweryfikowana z form komunikacji jest wymagana: email, telefon."
      }
    })
  }

  setFormData(performer: UpdateProfileModel): void {
    this.form.patchValue(performer);
    this.isLoading = false;
  }

  onSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.recommendationHttpService.updatePerformerRating(this.form.value)
      .subscribe({
      next: (resp: any) => {
        if (resp && resp.hasOwnProperty('code') && resp['code'] == 400) {
          this.errorMessage = transformErrorHelper(resp);
        } else {
          this.router.navigate(['/', 'recommendation', 'profile']);
        }
        this.isLoading = false;
      },
      error: error =>
        this.errorMessage = transformErrorHelper(error)
      });
  }
}
