import { Component } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { smsCodeMask } from "@shared/masks/sms-code.mask";
import { RecommendationHttpService } from "../../services/recommendation-http.service";
import { transformErrorHelper } from "@shared/helpers/transform-error.helper";
import { Router } from "@angular/router";

@Component({
  selector: 'app-recommendation-phone-verify',
  templateUrl: './recommendation-phone-verify.component.html',
  styleUrls: ['./recommendation-phone-verify.component.scss']
})
export class RecommendationPhoneVerifyComponent {
  performerPhone: string;
  codeMask = smsCodeMask;
  form = this.formBuilder.group({
    code: ['', Validators.required],
  });

  errorMessage: string | null;

  constructor(
    private formBuilder: FormBuilder,
    private recommendationHttpService: RecommendationHttpService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.performerPhone = history.state.performerPhone;
    if (!this.performerPhone) {
      this.getPerformer();
    }
    this.subscribeFormValueChanges();
  }

  subscribeFormValueChanges() {
    this.form.controls.code.valueChanges.subscribe(() => {
      if (this.errorMessage) {
        this.errorMessage = null;
      }
    });
  }

  getPerformer() {
    this.recommendationHttpService.getPerformerRating()
      .subscribe((performer) => {
        this.performerPhone = performer.phone_number;
      })
  }

  sendCode() {
    let code = this.form.value.code;

    if (code) {
      this.recommendationHttpService.changePhoneNo(this.performerPhone, code)
        .subscribe({
          next: () => {
            this.router.navigate(['/', 'recommendation', 'profile'])
          },
          error: (err) => {
            this.errorMessage = transformErrorHelper(err);
          }
        })
    }
  }
}
