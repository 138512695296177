<app-previous-page-navigator
    [prevRoute]="'/'"
    [title]="'Baza wiedzy'"></app-previous-page-navigator>
<div class="description">
    {{
        'Pobieraj dokumenty i dodawaj je do ulubionych.'
    }}
</div>
<div class="separator"></div>
<ng-container *ngFor="let course of courses">
    <div class="course">
        <div class="icon">
            <fa-icon
                class="menu-content-item-icon"
                [icon]="course.is_favourite ? fullStar : emptyStar"
                (click)="toggleFavourite(course)"></fa-icon>
        </div>
        <div class="text" (click)="downloadFile(course)">
            {{ course.name }}
        </div>
        <div class="download" (click)="downloadFile(course)">
            <fa-icon
                class="menu-content-item-icon"
                [icon]="faFileDownload"></fa-icon>
        </div>
    </div>
    <div class="separator list"></div>
</ng-container>
