import { Injectable } from '@angular/core';
import { GlobalStorageService } from '../storages/global-storage.service';
import { StoragePersisterWindow } from '../storages/persisters/storage-persister-window';
import { NativeService } from '@services/native.service';
import { BasketService } from '../../modules/shop/services/basket.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    readonly token = 'X-Token';

    constructor(
        private storage: GlobalStorageService,
        private readonly nativeService: NativeService,
        private readonly basket: BasketService,
    ) {}

    authenticate(token: string, isRememberMe: boolean): void {
        if (!isRememberMe) {
            this.storage.storageManager.currentPersister =
                new StoragePersisterWindow();
        }

        this.storage.store(this.token, token);

        this.nativeService.requestFirebaseTokenRefresh();
    }

    isAuthenticated(): boolean {
        return null !== this.storage.read(this.token);
    }

    getToken(): string {
        return <string>this.storage.read(this.token);
    }

    clearCredential(): void {
        this.clearStorage();
        this.nativeService.requestFirebaseTokenRefresh();
        this.basket.clear();
    }

    clearStorage(): void {
        this.storage.clear(this.token);
    }
}
