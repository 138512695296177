import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttpService } from '@services/http/api-http.service';
import { messageThread } from '../interfaces/thread.interface';
import { MessagesInThread } from '../interfaces/messageInThread.interface';
import { HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class MessagesHttpService {
    constructor(private readonly apiService: ApiHttpService) {}

    public getThreadList(): Observable<messageThread[]> {
        return this.apiService.get('messages/list');
    }
    public getThreadListPage(
        number: number,
    ): Observable<HttpResponse<messageThread[]>> {
        return this.apiService.getWithObserveResponse<messageThread[]>(
            `messages/list/${number}`,
        );
    }
    public getThreadWithMessages(id: number): Observable<MessagesInThread> {
        return this.apiService.get(`messages/thread/${id}`);
    }
    public postThreadReply(
        id: number,
        body: { message: string },
    ): Observable<any> {
        return this.apiService.post(`messages/thread/${id}/reply`, body);
    }
    public postNewThread(body: {
        title: string;
        message: string;
    }): Observable<any> {
        return this.apiService.post(`messages/thread/create`, body);
    }
}
