import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-form-label',
    templateUrl: './form-label.component.html',
})
export class FormLabelComponent {
    @Input() label?: string = '';
    @Input() for?: string;
    @Input() type?: string;
    @Input() customClasses: string[] = [];
    @Input() requiredIndicator: boolean = false;
}
