<app-previous-page-navigator
    [prevRoute]="'../'"
    [title]="'Wiadomości'"></app-previous-page-navigator>
<div class="content">
    <form>
        <div class="message">Wiadomości</div>
        <div class="text-response">
            <input [(ngModel)]="userReply" type="text" name="reply"/>
        </div>
    </form>
    <div class="send-button" (click)="reply()">Odpowiedz</div>
</div>
