import { StoragePersisterInterface } from '../persisters/storage-persister.interface';

export class StorageManager {
    public currentPersister?: StoragePersisterInterface;

    constructor(private readonly persisters: StoragePersisterInterface[]) {}

    initialize(): void {
        for (const persister of this.persisters) {
            try {
                if (persister.hasData('TestingStore')) {
                    persister.removeData('TestingStore');
                }

                persister.saveData('TestingStore', 'TestingStore');
                persister.removeData('TestingStore');
                this.currentPersister = persister;

                return;
            } catch (error) {
                // try another
            }
        }

        throw new Error('No peristers supported.');
    }

    store(key: string, value: string): void {
        if (this.currentPersister == null) {
            throw new Error('Storage not initialized.');
        }

        if (this.currentPersister.hasData(key)) {
            this.currentPersister.removeData(key);
        }

        this.currentPersister.saveData(key, value);
    }

    read(key: string): string | null {
        if (this.currentPersister == null) {
            throw new Error('Storage not initialized.');
        }

        if (this.currentPersister.hasData(key)) {
            return this.currentPersister.getData(key);
        }

        return null;
    }

    clear(key: string): void {
        if (this.currentPersister == null) {
            throw new Error('Storage not initialized.');
        }

        if (this.currentPersister.hasData(key)) {
            this.currentPersister.removeData(key);
        }
    }
}
