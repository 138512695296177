import { Injectable } from '@angular/core';
import { StoragePersisterWeb } from './persisters/storage-persister-web';
import { StoragePersisterWindow } from './persisters/storage-persister-window';
import { StoragePersisterInterface } from './persisters/storage-persister.interface';
import { StorageManager } from './managers/storage-manager';
import { StoragePersisterCookie } from '@services/storages/persisters/storage-persister-cookie';

@Injectable({
    providedIn: 'root',
})
export class GlobalStorageService {
    public readonly storageManager: StorageManager;

    private readonly persisters: StoragePersisterInterface[] = [
        new StoragePersisterCookie(),
        new StoragePersisterWeb(localStorage),
        new StoragePersisterWeb(sessionStorage),
        new StoragePersisterWindow(),
    ];

    constructor() {
        this.storageManager = new StorageManager(this.persisters);
    }

    initialize(): void {
        this.storageManager.initialize();
    }

    store(key: string, value: string): void {
        this.storageManager.store(key, value);
    }

    read(key: string): string | null {
        return this.storageManager.read(key);
    }

    clear(key: string): void {
        this.storageManager.clear(key);
    }
}
