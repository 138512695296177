<app-previous-page-navigator
  [prevRoute]="backButtonUrl"
  [title]="'Moje dane superwykonawcy'"
></app-previous-page-navigator>

<div
  [appFullHeightPage]="{hasNavigation: false}"
  [appLoading]="isLoading"
  class="container sp-con-combo"
>
  <section *ngIf="!isLoading && performer" class="profile">
    <div class="profile-header">
      <div class="profile-avatar">
        <input
          #fileUpload
          (change)="onFileSelected($event)"
          accept="image/png,image/jpg"
          class="profile-avatar-input"
          type="file"
        />
        <div
          (click)="fileUpload.click()"
          *ngIf="performer.avatar_url"
          [style.background-image]="'url(' + performer.avatar_url + ')'"
          class="profile-avatar-image"
        ></div>

        <ng-container *ngIf="!performer.avatar_url">
          <div
            (click)="fileUpload.click()"
            [style.background-image]="''"
            class="profile-avatar-image">
            +
          </div>
        </ng-container>
      </div>

      <div class="ff-avr-blc fs-16">{{ performer.first_name }} {{ performer.last_name }}</div>
      <div class="ff-avr-nor fs-14 profile-title">{{ performer.profile_status }}</div>
    </div>

    <hr class="divider"/>

    <div class="profile-data-entry">
      <div class="label">Adres</div>
      <div class="value">
        {{ performer.street }} {{ performer.house_number ? performer.house_number : '-' }}{{ performer.local_number ? ('/' + performer.local_number) : '' }}
      </div>
    </div>

    <div class="profile-data-entry">
      <div class="label">Kod pocztowy</div>
      <div class="value">{{ performer.post_code }}</div>
    </div>

    <div class="profile-data-entry">
      <div class="label">Miejscowość</div>
      <div class="value">{{ performer.city }}</div>
    </div>

    <div class="profile-data-entry">
      <div class="label">Telefon</div>
      <div class="value">{{ performer.phone_number }}</div>
      <p *ngIf="performer.phone_pending" class="text-errors fs-14 lh-16">
        Ten numer telefonu nie został zweryfikowany. Kliknij poniżej, aby przejść do formularza lub wyślij ponownie,
        aby otrzymać nowy kod SMS
      </p>
      <div *ngIf="performer.phone_pending" class="buttons py-2">
        <button
          (click)="verifyPhoneChange()"
          class="btn btn-special"
          type="button"
        >
          otrzymano kod SMS
        </button>

        <button
          (click)="resendPhone()"
          class="btn btn-special"
          type="button"
        >
          wyślij ponownie
        </button>
      </div>
    </div>

    <div class="profile-data-entry">
      <div class="label">Email</div>
      <div class="value">{{ performer.email }}</div>
      <p *ngIf="performer.email_pending" class="text-errors fs-14 lh-16">
        Adres e-mail nie został zweryfikowany. Wejdź na skrzynkę aby go potwierdzić.
        W razie problemów kliknij poniżej, aby wysłać ponownie e-mail z linkiem aktywacyjnym.
      </p>
      <div *ngIf="performer.email_pending" class="py-2">
        <button
          (click)="resendEmail()"
          class="btn btn-special"
          type="button"
        >
          wyślij ponownie
        </button>
      </div>
    </div>

    <div class="profile-settings-controls center">
      <button
        [routerLink]="['/', 'recommendation', 'profile', 'edit']"
        class="btn btn-lg"
        type="button"
      >
        edytuj dane
      </button>
    </div>

    <hr class="divider nmt"/>

    <p class="text-errors fs-14 lh-16" *ngIf="!hasPerformerAccount && performer.email_pending && performer.phone_pending">
      Prosimy zweryfikowanie numeru telefonu i/lub adresu e-mail, aby kontynuować.
    </p>

    <p class="text-errors fs-14 lh-16" *ngIf="errorMessage" [innerHTML]="errorMessage"></p>
  </section>

  <div *ngIf="!isLoading && performer && !hasPerformerAccount" class="profile-settings-controls-row">
    <div class="profile-settings-controls-col">
      <button
        (click)="verifyAndCreateAccount()"
        [disabled]="(!performer.phone_number && !performer.email) || (performer.phone_number && !performer.email && performer.phone_pending) || (performer.email && !performer.phone_number && performer.email_pending)"
        class="btn btn-primary btn-lg"
        type="button"
      >
        Dalej
      </button>
    </div>
  </div>
</div>
