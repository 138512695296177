import { Component, Input } from '@angular/core';
import {
    faChevronLeft,
    IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'app-previous-page-navigator',
    templateUrl: './previous-page-navigator.component.html',
    styleUrls: ['./previous-page-navigator.component.scss'],
})
export class PreviousPageNavigatorComponent {
    @Input() prevRoute: string | any[] | undefined;
    @Input() title: string = '';
    @Input() additionalRoute: string | any[] | undefined;
    @Input() additionalRouteIcon: IconDefinition | undefined;

    public readonly icons = {
        faChevronLeft,
    };

    public isRouteExternal(route: string | any[] | undefined): boolean {
        if (route === undefined || route instanceof Array) {
            return false;
        }

        try {
            const url = new URL(route);

            return url.protocol === 'http:' || url.protocol === 'https:';
        } catch (err) {}

        return false;
    }
}
