import { Component, Input } from '@angular/core';
import { InputmaskOptions } from '@ngneat/input-mask';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-form-input',
    templateUrl: './form-input.component.html',
})
export class FormInputComponent {
    @Input() type: string = 'text';
    @Input() inputMode?: string;
    @Input() id?: string;
    @Input() name?: string;
    @Input() placeholder?: string = '';
    @Input() inputInputMask?: InputmaskOptions<any>;
    @Input() controlName: string | null = null;
    @Input() form!: FormGroup;
    @Input() isReadonly: boolean = false;
}
