import {enableProdMode, LOCALE_ID} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import * as Sentry from '@sentry/angular';
import {BrowserTracing} from '@sentry/tracing';
import Config from 'src/config.json';

if (Config.SENTRY_DSN && Config.SENTRY_DSN !== 'NULL') {
    Sentry.init({
        dsn: Config.SENTRY_DSN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.2,
        debug: false,
        environment: Config.CI_ENVIRONMENT_NAME,
        release: `${Config.CI_PROJECT_PATH_SLUG}@${Config.CI_COMMIT_SHORT_SHA}`,
        ignoreErrors: ['Non-Error exception captured'],
    });
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule, {
        providers: [{provide: LOCALE_ID, useValue: 'pl-PL'}],
    })
    .catch(err => console.error(err));
