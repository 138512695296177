import { Component, OnInit } from '@angular/core';
import { listMock } from '../../mocks/list.mock';
import {
    faFileDownload,
    faStar as emptyStar,
} from '@fortawesome/pro-regular-svg-icons';
import { faStar as fullStar } from '@fortawesome/pro-solid-svg-icons';
import { knowledgeBaseCourse } from '../../interfaces/knowledge-base-list.interface';
import { KnowledgeBaseHttpService } from '../../services/knowledge-base-http.service';
import { map, startWith, take, tap } from 'rxjs';
import { faFileArrowDown } from '@fortawesome/pro-light-svg-icons';
@Component({
    selector: 'app-knowledge-base-list',
    templateUrl: './knowledge-base-list.component.html',
    styleUrls: ['./knowledge-base-list.component.scss'],
})
export class KnowledgeBaseList implements OnInit {
    protected readonly faFileArrowDown = faFileArrowDown;
    protected readonly faFileDownload = faFileDownload;
    courses: knowledgeBaseCourse[] | null = null;
    // list = listMock; MOCK DATA
    protected readonly emptyStar = emptyStar;
    protected readonly fullStar = fullStar;

    constructor(private knowledgeBaseHttpService: KnowledgeBaseHttpService) {}

    ngOnInit() {
        this.fetchAndComputeData();
    }

    fetchAndComputeData() {
        this.knowledgeBaseHttpService
            .getKnowledgeBaseList()
            .pipe(
                // startWith(listMock), // MOCKED DATA!!!!!
                take(1),
                map(courses => {
                    return courses.sort(this.sortByPriorityAndFavourity);
                }),
            )
            .subscribe(courses => {
                this.courses = courses;
                console.log(courses);
            });
    }

    sortByPriorityAndFavourity(
        course1: knowledgeBaseCourse,
        course2: knowledgeBaseCourse,
    ) {
        if (course1.is_favourite && !course2.is_favourite) return -1;
        if (!course1.is_favourite && course2.is_favourite) return 1;
        if (course1.is_favourite === course2.is_favourite) {
            if (course1.priority > course2.priority) return -1;
            if (course1.priority === course2.priority) return 0;
            if (course1.priority < course2.priority) return 1;
        }
        return 0;
    }

    toggleFavourite(course: knowledgeBaseCourse) {
        course.is_favourite = !course.is_favourite;
        if (course.is_favourite) {
            this.knowledgeBaseHttpService
                .patchKnowledgeBaseAddFavourite(course.id)
                .subscribe();
        } else {
            this.knowledgeBaseHttpService
                .patchKnowledgeBaseRemoveFavourite(course.id)
                .subscribe();
        }
    }
    downloadFile(course: knowledgeBaseCourse) {
        this.knowledgeBaseHttpService
            .getKnowledgeBaseFile(course.id)
            .subscribe(blob => {
                const url = URL.createObjectURL(blob.body as Blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${course.name}.pdf`; // Set the desired file name
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            });
    }
}
