import { RecommendationStatus } from "../models/recommendation.model";

export function getRecommendationStatus(status: string): string {
  switch (status) {
    case RecommendationStatus.REQUEST:
      return 'request';
    case RecommendationStatus.PENDING:
      return 'pending';
    case RecommendationStatus.ACCEPTED:
      return 'accepted';

    default:
      return 'default';
  }
}
