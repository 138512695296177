<header class="previous-page-navigator-wrapper center">
    <div class="previous-page-navigator-inner">
        <a [routerLink]="prevRoute" class="btn-icon btn-primary previous-page-navigator-button">
            <fa-icon [icon]="icons.faChevronLeft"></fa-icon>
        </a>
        <h5 class="previous-page-navigator-title"
            [ngClass]="additionalRoute === undefined ? 'no-additional' : ''"
        >{{ title }}</h5>
        <ng-container *ngIf="additionalRoute !== undefined && additionalRouteIcon !== undefined">
            <ng-container *ngIf="isRouteExternal(additionalRoute); else internalAdditionalRouteTpl">
                <a [href]="additionalRoute"
                   [target]="'_blank'"
                   class="btn-icon btn-primary previous-page-navigator-button-additional"
                >
                    <fa-icon [icon]="additionalRouteIcon"></fa-icon>
                </a>
            </ng-container>
            <ng-template #internalAdditionalRouteTpl>
                <a [routerLink]="additionalRoute"
                   class="btn-icon btn-primary previous-page-navigator-button-additional"
                >
                    <fa-icon [icon]="additionalRouteIcon"></fa-icon>
                </a>
            </ng-template>
        </ng-container>
    </div>
</header>
