import { Component, OnInit } from '@angular/core';
import { messagesThreads } from '../../mocks/threads.mock';
import { ActivatedRoute, Router } from '@angular/router';
import {
    faChevronLeft,
    faChevronRight,
    faChevronsLeft,
    faChevronsRight,
    faPlus,
    faReply,
} from '@fortawesome/pro-solid-svg-icons';
import { MessagesHttpService } from '../../services/messages-http.service';
import { messageThread } from '../../interfaces/thread.interface';
import { map, tap } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-messages-threads-list',
    templateUrl: './messages-threads-list.component.html',
    styleUrls: ['./messages-threads-list.component.scss'],
})
export class MessagesThreadsList implements OnInit {
    protected readonly faReply = faReply;
    protected readonly faPlus = faPlus;
    protected readonly faChevronLeft = faChevronLeft;
    protected readonly faChevronsLeft = faChevronsLeft;
    protected readonly faChevronsRight = faChevronsRight;
    protected readonly faChevronRight = faChevronRight;

    messagesThreads: messageThread[] | null | undefined;
    pageNumber = 1;
    maxPageNumber = 1;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private messagesHttpService: MessagesHttpService,
    ) {}

    ngOnInit() {
        this.getPagesFromPatrameters();
    }

    getPagesFromPatrameters() {
        this.route.paramMap.subscribe(params => {
            this.pageNumber = parseInt(params.get('page') as string);
            if (!this.pageNumber) {
                this.pageNumber = 1;
                this.router.navigate(['./', { page: 1 }], {
                    relativeTo: this.route,
                });
            }
            this.fetchAndComputeData();
        });
    }

    fetchAndComputeData() {
        this.messagesHttpService
            .getThreadListPage(this.pageNumber)
            .pipe(
                tap(this.extractMaxPage.bind(this)),
                map(this.extractDate.bind(this)),
            )
            .subscribe(response => {
                this.messagesThreads = response.body;
            });
    }
    extractMaxPage(response: HttpResponse<messageThread[]>) {
        const items = parseInt(
            response.headers.get('X-Pagination-Items-Count') as string,
        );
        const perpage = parseInt(
            response.headers.get('X-Pagination-Per-Page') as string,
        );
        this.maxPageNumber = Math.ceil(items / perpage);
    }
    extractDate(response: HttpResponse<messageThread[]>) {
        response?.body?.forEach(thread => {
            const date = new Date(thread.created_at);
            const year = date.getFullYear();
            // getMonth() returns a 0-based index, so add 1 to get the correct month number
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            thread.created_at = `${day}-${month}-${year}`;
        });
        return response;
    }
    nextPage() {
        if (this.pageNumber + 1 <= this.maxPageNumber) {
            this.router.navigate(['./', { page: this.pageNumber + 1 }], {
                relativeTo: this.route,
            });
        }
    }
    prevPage() {
        if (this.pageNumber - 1 >= 1) {
            this.router.navigate(['./', { page: this.pageNumber - 1 }], {
                relativeTo: this.route,
            });
        }
    }
    lastPage() {
        this.router.navigate(['./', { page: this.maxPageNumber }], {
            relativeTo: this.route,
        });
    }
    firstPage() {
        this.router.navigate(['./', { page: 1 }], {
            relativeTo: this.route,
        });
    }
    navigatToThread(id: number) {
        this.router.navigate([id], { relativeTo: this.route });
    }
    navigatToNewMessage() {
        this.router.navigate(['nowa'], { relativeTo: this.route });
    }
}
