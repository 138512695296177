<div
    class="form-group"
    [class.form-group-checkbox]="type === 'checkbox'"
    [class.ng-invalid]="
        controlName !== null && form.controls[controlName].invalid
    "
    [class.ng-touched]="
        controlName !== null && form.controls[controlName].touched
    "
    [class.ng-dirty]="controlName !== null && form.controls[controlName].dirty"
    [formGroup]="form">
    <ng-container *ngIf="label !== undefined && type !== 'checkbox' && type !== 'radio'">
        <app-form-label
            [label]="label"
            [for]="id"
            [type]="type"
            [requiredIndicator]="requiredIndicator"
        ></app-form-label>
    </ng-container>
    <ng-container *ngIf="type === 'textarea'; else other">
        <app-form-text
            [id]="id"
            [placeholder]="placeholder"
            [controlName]="controlName"
            [form]="form"
            [rows]="rows"
        ></app-form-text>
    </ng-container>
    <ng-template #other>
        <ng-container *ngIf="type === 'checkbox' || type === 'radio'; else input">
            <app-form-check
                    [id]="id"
                    [controlName]="controlName ?? ''"
                    [form]="form"
            ></app-form-check>
            <app-form-label *ngIf="label !== undefined"
                    [label]="label"
                    [for]="id"
                    [type]="'checkbox'"
            >
            </app-form-label>
        </ng-container>
        <ng-template #input>
            <app-form-input
                    [type]="type"
                    [inputMode]="inputMode"
                    [id]="id"
                    [name]="name"
                    [placeholder]="placeholder"
                    [inputInputMask]="inputInputMask"
                    [controlName]="controlName"
                    [form]="form"
                    [isReadonly]="isReadonly"
            >
            </app-form-input>
        </ng-template>
    </ng-template>
    <ng-container
        *ngIf="
            controlName !== null &&
            form.controls[controlName].hasError('custom')
        ">
        <div class="form-group-custom-error">
            {{ form.controls[controlName].errors!['custom'] }}
        </div>
    </ng-container>
</div>
