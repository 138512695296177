<app-previous-page-navigator
  [title]="'Moje dane superwykonawcy'"
  [prevRoute]="['/', 'recommendation', 'profile']"
>
</app-previous-page-navigator>

<div
  [appFullHeightPage]="{hasNavigation: false}"
  class="container sp-con-combo"
  *ngIf="form"
>
  <section class="profile-verify-code">
    <h4 class="login-page-title center">Wpisz poniżej wysłany do Ciebie<br /> kod SMS, aby potwierdzić zmianę.</h4>

    <app-form-control
      [id]="'code'"
      [placeholder]="'KOD SMS'"
      [inputInputMask]="codeMask"
      [inputMode]="'numeric'"
      [controlName]="'code'"
      [form]="form"
    ></app-form-control>

    <p class="text-errors fs-14 lh-16" *ngIf="errorMessage">
      {{ errorMessage }}
    </p>
  </section>

  <button
    (click)="sendCode()"
    [disabled]="form?.controls?.code?.invalid"
    class="btn btn-primary btn-lg submit"
  >
    WYŚLIJ
  </button>
</div>
