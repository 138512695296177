<app-previous-page-navigator
  [prevRoute]="'/profil'"
  [title]="'Rekomendacje'"
></app-previous-page-navigator>

<div
  [appFullHeightPage]="{hasNavigation: false}"
  class="container sp-con-combo"
  [appLoading]="isLoading"
>
  <section class="recommendation-certificate" *ngIf="!isLoading">
    <div class="description">
      <ng-container *ngIf="isUserCertificate">
        CERTYFIKOWANY SUPERWYKONAWCA

        <br><br><br>

        Możesz tutaj utworzyć swój profil w serwisie superwykonawca.pl. <br><br>
        Po założeniu swojego konta, zostaniesz
        naniesiony na mapę Wykonawców w Polsce, dzięki czemu kiedy Inwestor będzie szukał Wykonawcy, będzie mógł
        trafić na Ciebie. <br><br> Dane profilowe zostały automatycznie przeniesione z aplikacji, możesz je jednak zmienić
        tak aby na stronie pojawiły się np. inne dane kontaktowe, zdjęcie profilowe itd.
      </ng-container>

      <ng-container *ngIf="!isUserCertificate">
        SUPERWYKONAWCA bez certyfikatu

        <br><br><br>

        Strona dostępna tylko <br> dla Certyfikowanych Wykonawców. <br><br>
        Status Certyfikowanego Wykonawcy możesz zdobyć po przez zgłoszenie <br> trzech realizacji jednego typu
        (czyli np. trzy razy ocieplenie poddasza). <br> <br> Po uzyskaniu certyfikatu wróć tutaj aby założyć swój profil
        na stronie superwykonawca.pl. <br> Jest to strona na której Inwestorzy mogą znaleźć Wykonawce ROCKWOOL w swojej okolicy.
      </ng-container>
    </div>

    <button
      (click)="go()"
      class="btn btn-primary btn-lg submit"
    >
      {{ isUserCertificate ? "DALEJ" : "OK" }}
    </button>
  </section>
</div>
