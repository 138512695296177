import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnowledgeBaseRoutingModule } from './knowledge-base-routing.module';
import { KnowledgeBaseList } from './components/knowledge-base-list/knowledge-base-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [KnowledgeBaseList],
    imports: [
        CommonModule,
        KnowledgeBaseRoutingModule,
        FontAwesomeModule,
        SharedModule,
    ],
})
export class KnowledgeBaseModule {}
