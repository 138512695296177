<app-previous-page-navigator
    [prevRoute]="'/'"
    [title]="'Wiadomości'"></app-previous-page-navigator>

<div class="content">
    <div class="threads">
        <div class="separator"></div>
        <ng-container *ngFor="let thread of messagesThreads">
            <div class="thread" (click)="navigatToThread(thread.id)">
                <div class="date">
                    {{ thread.created_at }}
                </div>
                <div class="text">
                    {{ thread.title }}
                </div>
            </div>
            <div class="separator list"></div>
        </ng-container>
    </div>
    <div class="navigation">
        <fa-icon [icon]="faChevronsLeft" style="color: black" (click)="firstPage()"></fa-icon>
        <fa-icon [icon]="faChevronLeft" style="color: black" (click)="prevPage()"></fa-icon>
        <div class="page-number">{{pageNumber}}</div>
        <fa-icon [icon]="faChevronRight" style="color: black" (click)="nextPage()"></fa-icon>
        <fa-icon [icon]="faChevronsRight" style="color: black" (click)="lastPage()"></fa-icon>
    </div>
</div>
<div class="new-message">
    <fa-icon
        [icon]="faPlus"
        style="color: white"
        (click)="navigatToNewMessage()"></fa-icon>
</div>
