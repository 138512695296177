import { Injectable } from '@angular/core';
import Config from 'src/config.json';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import isAbsoluteUrl from 'is-absolute-url';

@Injectable({
    providedIn: 'root',
})
export class ApiHttpService {
    readonly apiUrl = Config.API_URL;

    constructor(private http: HttpClient) {}

    public get<T>(
        path: string,
        params?:
            | HttpParams
            | {
                  [param: string]:
                      | string
                      | number
                      | boolean
                      | ReadonlyArray<string | number | boolean>;
              },
    ): Observable<T> {
        return this.http.get<T>(this.path(path), { params: params });
    }

    public getWithObserveResponse<T>(
        path: string,
    ): Observable<HttpResponse<T>> {
        return this.http.get<T>(this.path(path), {
            observe: 'response',
        });
    }

    public getBlob(
        path: string,
        accept: string = 'application/pdf',
    ): Observable<HttpResponse<Blob>> {
        return this.http.get(this.path(path), {
            headers: new HttpHeaders({
                Accept: accept,
            }),
            observe: 'response',
            responseType: 'blob',
        });
    }

    public post<T>(path: string, body?: any, options?: {}): Observable<T> {
        return this.http.post<T>(this.path(path), body, options);
    }

    public put<T>(path: string, body?: any, options?: {}): Observable<T> {
        return this.http.put<T>(this.path(path), body, options);
    }

    public patch<T>(path: string, body?: any, options?: {}): Observable<T> {
        return this.http.patch<T>(this.path(path), body, options);
    }

    public delete<T>(path: string): Observable<T> {
        return this.http.delete<T>(this.path(path));
    }

    private path(path: string): string {
        if (isAbsoluteUrl(path)) {
            return path;
        }

        return `${this.apiUrl}${path}`;
    }
}
