<app-previous-page-navigator [prevRoute]="'/'" [title]="'szkolenia'">
</app-previous-page-navigator>
<div class="trainings-container">
    <div class="separator"></div>
    <div class="trainings" routerLink="/szkolenia/wykonawca">
        <div class="text">MOJE SZKOLENIA</div>
        <fa-icon [icon]="faAngleRight"></fa-icon>
    </div>
    <div class="separator"></div>
    <div class="trainings" routerLink="/szkolenia/wszystkie">
        <div class="text">LISTA SZKOLEŃ</div>
        <fa-icon [icon]="faAngleRight"></fa-icon>
    </div>
    <div class="separator"></div>
</div>
