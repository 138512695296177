import { Component, OnInit } from '@angular/core';
import { messagesInThread } from '../../mocks/messagesInThread.mock';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagesHttpService } from '../../services/messages-http.service';

@Component({
    selector: 'app-message-reply',
    templateUrl: './message-reply.component.html',
    styleUrls: ['./message-reply.component.scss'],
})
export class MessageReply implements OnInit {
    id = 0;
    userReply: string = '';
    protected readonly messagesInThread = messagesInThread;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private messagesHttpService: MessagesHttpService,
    ) {}

    ngOnInit() {
        this.id = parseInt(
            this.route.snapshot.paramMap.get('threadId') as string,
        );
    }
    reply() {
        this.messagesHttpService
            .postThreadReply(this.id, {
                message: this.userReply,
            })
            .subscribe(
                () => {
                    this.navigateBackToThread();
                },
                () => {},
            );
    }
    navigateBackToThread() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
