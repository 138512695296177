import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecommendationComponent } from "./pages/recommendation/recommendation.component";
import { RecommendationProfileComponent } from "./pages/recommendation-profile/recommendation-profile.component";
import {
  RecommendationProfileEditComponent
} from "./pages/recommendation-profile-edit/recommendation-profile-edit.component";
import { RecommendationDetailsComponent } from "./pages/recommendation-details/recommendation-details.component";
import { RecommendationSendComponent } from "./pages/recommendation-send/recommendation-send.component";
import { SelfComponent } from "./self/self/self.component";
import {
  RecommendationCertificateComponent
} from "./pages/recommendation-certificate/recommendation-certificate.component";
import {
  RecommendationPhoneVerifyComponent
} from "./pages/recommendation-phone-verify/recommendation-phone-verify.component";

const routes: Routes = [
  {
    path: 'recommendation-certificate',
    component: RecommendationCertificateComponent,
  },
  {
    path: 'recommendation',
    component: SelfComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: RecommendationComponent,

      },
      {
        path: 'details/:id',
        children: [
          {
            path: '',
            component: RecommendationDetailsComponent,
            pathMatch: 'full',
          },
          {
            path: 'send',
            component: RecommendationSendComponent,
          },
        ]
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            component: RecommendationProfileComponent,
            pathMatch: 'full',

          },
          {
            path: 'edit',
            component: RecommendationProfileEditComponent,
          },
          {
            path: 'verify',
            component: RecommendationPhoneVerifyComponent,
          },
        ]
      },
    ]
  },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RecommendationRouting {}
