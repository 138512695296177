import { Injectable, NgZone } from '@angular/core';
import { ApiHttpService } from '@services/http/api-http.service';
import { Observable } from 'rxjs';
import * as Sentry from '@sentry/angular';

@Injectable({
    providedIn: 'root',
})
export class NativeService {
    screnOrientation = '';
    // for android
    androidInterface: {
        requestFirebaseTokenRefresh: () => void;
        closeApp: () => void;
        // @ts-ignore
    } = window['NativeInterface'];

    // for ios
    reactInterface: {
        postMessage: (event: string) => void;
        // @ts-ignore
    } = window['ReactNativeWebView'];

    constructor(
        private readonly zone: NgZone,
        private readonly apiService: ApiHttpService,
    ) {
        this.setAndroidServiceInWindow();
        this.startOrientationManager();
    }

    private setAndroidServiceInWindow() {
        // @ts-ignore
        window['NativeBridge'] = this;
    }

    closeApp() {
        if (this.androidInterface) {
            this.androidInterface.closeApp();
        }
    }

    closeWindow2() {
        if (
            window.location.pathname === '/main/home' ||
            window.location.pathname === '/main/login'
        ) {
            this.zone.run(() => {
                // this.dialogService.openAlert(
                //     {
                //         data: {
                //             title: 'Uwaga!',
                //             description:
                //                 'Czy na pewno chcesz zamknąć aplikację?',
                //             isConfirmAlert: true,
                //         },
                //     },
                //     (confirm: any) => {
                //         if (confirm) {
                //             this.closeApp();
                //         }
                //     },
                // );
            });
        }
    }

    requestFirebaseTokenRefresh(): void {
        if (this.androidInterface) {
            try {
                this.androidInterface.requestFirebaseTokenRefresh();
            } catch (e) {
                Sentry.captureException(e);
            }
        }

        if (this.reactInterface) {
            try {
                this.reactInterface.postMessage('fcm_token_refresh');
            } catch (e) {
                Sentry.captureException(e);
            }
        }
    }

    registerForPush(token: string, type: string, appVersion: string) {
        this.pushTokenRequest(token, type, appVersion).subscribe(() => {});
    }

    pushTokenRequest(
        token: string,
        type: string,
        appVersion: string,
    ): Observable<any> {
        return this.apiService.post('push-token', {
            token: token,
            app_version: appVersion,
            type: type,
        });
    }
    startOrientationManager() {
        this.screnOrientation = screen.orientation.type;
        screen.orientation.addEventListener('change', () => {
            this.screnOrientation = screen.orientation.type;
            // console.log(this.screnOrientation);
        });
    }
}
