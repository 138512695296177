import { Component, Input } from '@angular/core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-form-check',
    templateUrl: './form-check.component.html',
    styleUrls: ['./form-check.component.scss'],
})
export class FormCheckComponent {
    @Input() form!: FormGroup;
    @Input() controlName!: string;
    @Input() id?: string;

    public readonly icon = faCheck;

    public onClick(): void {
        this.form.get(this.controlName)?.markAsTouched();
    }
}
