import { Component, OnInit } from '@angular/core';
import { catchError, forkJoin, mergeMap, of } from "rxjs";
import { RecommendationHttpService } from "../../services/recommendation-http.service";
import { ProfileModel } from "../../models/performer-profile.model";
import { ProfileHttpService } from "../../../profile/services/profile-http.service";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { transformErrorHelper } from "@shared/helpers/transform-error.helper";

@Component({
  selector: 'app-recommendation-profile',
  templateUrl: './recommendation-profile.component.html',
  styleUrls: ['./recommendation-profile.component.scss']
})
export class RecommendationProfileComponent implements OnInit {
  isLoading = true;
  performer!: ProfileModel | null;
  backButtonUrl: string[];
  form: FormGroup;
  errorMessage: any;
  hasPerformerAccount: boolean | undefined;

  constructor(
    private recommendationHttpService: RecommendationHttpService,
    private profileHttpService: ProfileHttpService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.subscribePerformer();
  }

  subscribePerformer() {
    forkJoin([
      this.profileHttpService.getProfile(),
      this.recommendationHttpService.getPerformerRating(),
    ])
      .pipe(
        catchError(() => {
          return of([null, null]);
        }),
      )
      .subscribe({
        next: ([user, performer]) => {
          this.backButtonUrl = user?.has_performer_profile ? ['/', 'recommendation'] : ['/', 'recommendation-certificate'];
          if (performer && user) {
            performer.profile_status = user.profile_status;
          }
          this.hasPerformerAccount = user?.has_performer_profile;
          this.performer = performer;
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.errorMessage = transformErrorHelper(err);
        }
      })
  }

  onFileSelected(event: any): void {
    const avatar = event.target.files[0];

    if (avatar) {
      this.isLoading = true;

      const avatarPayload = new FormData();
      avatarPayload.set('avatar', avatar);

      this.recommendationHttpService
        .uploadAvatar(avatarPayload)
        .pipe(mergeMap(() => this.recommendationHttpService.getPerformerRating()))
        .subscribe({
          next: (value) => {
            this.performer = value;
            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this.errorMessage = transformErrorHelper(err);
          }
        });
    }
  }

  verifyAndCreateAccount() {
    this.recommendationHttpService.verifyProfile()
      .subscribe({
        next: (resp: any) => {
          if (resp && resp.hasOwnProperty('code') && resp['code'] == 400) {
            this.errorMessage = transformErrorHelper(resp);
          } else {
            this.router.navigate(['/', 'recommendation'])
          }
          this.isLoading = false;
        },
        error: (err) => {
          this.errorMessage = transformErrorHelper(err);
          this.isLoading = false;
        }
      })
  }

  resendEmail() {
    if (!this.performer?.email) {
      return;
    }

    this.recommendationHttpService.retryEmail()
      .subscribe(() => {
        this.ngOnInit();
      });
  }

  resendPhone() {
    this.recommendationHttpService.retryPhoneNo()
      .subscribe({
        complete: () =>
          this.verifyPhoneChange()
      });
  }

  verifyPhoneChange() {
    this.router.navigate(['/', 'recommendation', 'profile', 'verify'], {
      state: {
        performerPhone: this.performer?.phone_number,
      }
    });
  }
}
