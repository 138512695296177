import { Injectable } from '@angular/core';
import { ApiHttpService } from '@services/http/api-http.service';
import { Observable } from 'rxjs';
import { training } from '../interfaces/training.interface';
import { HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class TrainingsHttpService {
    constructor(private readonly apiService: ApiHttpService) {}

    public getTrainingsList(): Observable<training[]> {
        return this.apiService.get('training/list');
    }
    public getTrainingsListPage(
        number: number,
    ): Observable<HttpResponse<training[]>> {
        return this.apiService.getWithObserveResponse<training[]>(
            `training/list/${number}`,
        );
    }
    public getTrainingsUser(): Observable<training[]> {
        return this.apiService.get('training/my');
    }
    public getTrainingsUserPage(
        number: number,
    ): Observable<HttpResponse<training[]>> {
        return this.apiService.getWithObserveResponse<training[]>(
            `training/my/${number}`,
        );
    }
    public patchSignUpForTraining(id: number): Observable<any> {
        return this.apiService.patch(`training/sign-up/${id}`);
    }
}
