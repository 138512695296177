import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-form-text',
    templateUrl: './form-text.component.html',
    styleUrls: ['form-text.component.scss'],
})
export class FormTextComponent {
    @Input() id?: string;
    @Input() placeholder?: string = '';
    @Input() controlName: string | null = null;
    @Input() form!: FormGroup;
    @Input() rows?: number;
}
