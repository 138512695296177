import { Injectable } from '@angular/core';
import { BasketStorage } from './basket-storage';
import { BasketStorageArrayService } from './basket-storage-array.service';
import { BasketItemInterface } from '../interfaces/basket.interface';
import { StorageManager } from '@services/storages/managers/storage-manager';
import { StoragePersisterInterface } from '@services/storages/persisters/storage-persister.interface';
import { StoragePersisterWeb } from '@services/storages/persisters/storage-persister-web';
import { StoragePersisterWindow } from '@services/storages/persisters/storage-persister-window';

@Injectable({
    providedIn: 'root',
})
export class BasketStorageGlobalService implements BasketStorage {
    private readonly persisters: StoragePersisterInterface[] = [
        new StoragePersisterWeb(localStorage),
        new StoragePersisterWeb(sessionStorage),
        new StoragePersisterWindow(),
    ];
    private arrayStorage: BasketStorageArrayService;
    private globalStorage: StorageManager;

    constructor() {
        this.globalStorage = new StorageManager(this.persisters);
        this.globalStorage.initialize();

        this.arrayStorage = new BasketStorageArrayService();
    }

    public set(items: BasketItemInterface[]): void {
        this.arrayStorage.set(items);
        this.store();
    }

    public put(item: BasketItemInterface): BasketItemInterface {
        this.arrayStorage.set(this.all());
        let newItem = this.arrayStorage.put(item);
        this.store();

        return newItem;
    }

    public remove(item: BasketItemInterface): void {
        this.arrayStorage.set(this.all());
        this.arrayStorage.remove(item);

        this.store();
    }

    public all(): BasketItemInterface[] {
        const data = this.globalStorage.read('shopBasket');

        if (!data) {
            return [];
        }

        return JSON.parse(data) as BasketItemInterface[];
    }

    public clear(): void {
        this.arrayStorage.clear();
        this.globalStorage.clear('shopBasket');
    }

    public count(): number {
        return this.all().length;
    }

    private store(): void {
        this.globalStorage.store(
            'shopBasket',
            JSON.stringify(this.arrayStorage.all()),
        );
    }
}
