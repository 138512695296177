import { Injectable } from '@angular/core';
import { ShopProductListInterface } from '../interfaces/shop.interface';
import { BasketItemInterface } from '../interfaces/basket.interface';
import { Observable } from 'rxjs';
import { BasketStorage } from './basket-storage';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  constructor(private readonly storage: BasketStorage) {
  }

  public addProduct = (
    product: ShopProductListInterface,
    amountInterval: number,
    variantSelected?: string | number,
  ): Observable<BasketItemInterface> => {
    return new Observable(o => {
      const item = this.storage.put({
        productId: product.productId,
        name: product.name,
        thumbUri: product.thumbUri,
        price: product.price,
        amount: amountInterval,
        variant: variantSelected,
      } as BasketItemInterface);

      o.next(item);
    });
  };

  public updateBasketItem(
    item: BasketItemInterface,
    newAmount: number,
  ): Observable<BasketItemInterface> {
    return new Observable(o => {
      item.amount = newAmount - item.amount;
      const newItem = this.storage.put(item);

      o.next(newItem);
    });
  }

  public removeBasketItem(item: BasketItemInterface): void {
    this.storage.remove(item);
  }

  public clear(): void {
    this.storage.clear();
  }

  public getProducts = (): Observable<BasketItemInterface[]> => {
    return new Observable(o => o.next(this.storage.all()));
  };

  public countProducts = (): Observable<number> => {
    const count = this.storage
      .all()
      .map(item => item.amount)
      .reduce((sum, total) => sum + total, 0);

    return new Observable(o => o.next(count));
  };
}
