import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '@shared/shared.module';
import { MessagesThreadsList } from './components/messages-threads-list/messages-threads-list.component';
import { MessagesRoutingModule } from './messages-routing.module';
import { MessagesThread } from './components/messages-thread/messages-thread.component';
import { MessageReply } from './components/message-reply/message-reply.component';
import { MessageNew } from './components/message-new/message-new.component';

@NgModule({
    declarations: [
        MessagesThreadsList,
        MessagesThread,
        MessageReply,
        MessageNew,
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        SharedModule,
        MessagesRoutingModule,
    ],
})
export class MessagesModule {}
