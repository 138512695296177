import { Injectable } from '@angular/core';
import { BasketItemInterface } from '../interfaces/basket.interface';
import { BasketStorage } from './basket-storage';

@Injectable()
export class BasketStorageArrayService implements BasketStorage {
    private items: BasketItemInterface[] = [];

    public set(items: BasketItemInterface[]): void {
        this.items = items;
    }

    public put(item: BasketItemInterface): BasketItemInterface {
        let existingItem = this.find(item);

        if (undefined !== existingItem) {
            existingItem.amount += item.amount;

            return existingItem;
        }

        this.items.push(item);

        return item;
    }

    public remove(item: BasketItemInterface): void {
        const index = this.items.findIndex(
            currentItem => currentItem.productId === item.productId,
        );

        if (index <= -1) {
            return;
        }

        this.items.splice(index, 1);
    }

    public all(): BasketItemInterface[] {
        return this.items.slice();
    }

    public clear(): void {
        this.items = [];
    }

    public count(): number {
        return this.items.length;
    }

    private find(item: BasketItemInterface): BasketItemInterface | undefined {
        return this.items.find(
            currentItem => currentItem.productId === item.productId && currentItem.variant === item.variant,
        );
    }
}
