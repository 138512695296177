import { StoragePersisterInterface } from './storage-persister.interface';

export class StoragePersisterWindow implements StoragePersisterInterface {
    private storage!: { value: string | null };

    public hasData(key: string): boolean {
        this.initialize();

        return this.storage.value !== null;
    }

    public getData(key: string): string {
        this.initialize();

        const val = this.storage.value;

        if (null === val) {
            throw new Error('key does not exists');
        }

        return val;
    }

    public saveData(key: string, value: string): void {
        this.initialize();

        if (this.hasData(key)) {
            throw new Error('key already exists');
        }

        this.storage.value = value;
    }

    public removeData(key: string): void {
        this.initialize();

        if (!this.hasData(key)) {
            throw new Error('key does not exists');
        }

        this.storage.value = null;
    }

    protected initialize(): void {
        this.storage = (window as any)['StorageVariableJs'] || { value: null };
        (window as any)['StorageVariableJs'] = this.storage;
    }
}
