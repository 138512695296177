import { ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputMaskModule } from '@ngneat/input-mask';
import { TokenModule } from '@services/security/token/token.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BasketStorage } from './modules/shop/services/basket-storage';
import { BasketStorageGlobalService } from './modules/shop/services/basket-storage-global.service';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { AuthInterceptor } from '@shared/auth/auth.interceptor';
import '@angular/common/locales/global/pl';
import { MessagesModule } from './modules/messages/messages.module';
import { KnowledgeBaseModule } from './modules/knowledge-base/knowledge-base.module';
import { ErrorInterceptor } from './interceptors/http-errors.interceptor';
import { TrainingsModule } from './modules/trainings/trainings.module';
import { RecommendationModule } from './modules/recommendation/recommendation.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        TokenModule,
        FontAwesomeModule,
        InputMaskModule,
        BrowserAnimationsModule,
        MessagesModule,
        TrainingsModule,
        KnowledgeBaseModule,
        RecommendationModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: BasketStorage,
            useClass: BasketStorageGlobalService,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: ErrorInterceptor,
        //     multi: true,
        // },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
})
export class AppModule {}
