import { Component } from '@angular/core';
import { faAngleRight, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
    selector: 'app-trainings',
    templateUrl: './trainings.component.html',
    styleUrls: ['./trainings.component.scss'],
})
export class TrainingsComponent {
    constructor(private readonly router: Router) {}

    async goToAllTrainings() {
        await this.router.navigateByUrl('/wszystkie');
    }

    protected readonly faAngleRight = faAngleRight;
}
