import { Component, Input } from '@angular/core';
import { InputmaskOptions } from '@ngneat/input-mask';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-form-control',
    templateUrl: './form-control.component.html',
})
export class FormControlComponent {
    @Input() type:
        | 'text'
        | 'number'
        | 'password'
        | 'tel'
        | 'email'
        | 'textarea'
        | 'checkbox'
        | 'radio' = 'text';
    @Input() inputMode?: string;
    @Input() label?: string;
    @Input() id?: string;
    @Input() name?: string;
    @Input() placeholder?: string;
    @Input() inputInputMask?: InputmaskOptions<any>;
    @Input() form!: FormGroup;
    @Input() controlName: string | null = null;
    @Input() isReadonly: boolean = false;
    @Input() requiredIndicator: boolean = false;
    @Input() rows?: number;
}
