export const DICTIONARY_ERROR: any = {
  'first_name': 'Imię',
  'last_name': 'Nazwisko',
  'telephone': 'Telefon',
  'phone_number': 'Nr telefonu',
  'zip_code': 'Kod pocztowy',
  'city': 'Miasto',
  'branch': 'Oddział',
  'street': 'Ulica',
  'old_password': 'Aktualne hasło',
  'new_password': 'Nowe hasło',
  'locale_number': 'Numer domu i mieszkania',
  'email': 'E-mail',
  'firstName': 'Imię',
  'lastName': 'Nazwisko',
  'pesel': 'Pesel',
  'phone': 'Nr telefonu',
  'bankAccount': 'Nr konta bankowego',
  'postCode': 'Kod pocztowy',
  'homeNumber': 'Nr domu',
  'flatNumber': 'Nr mieszkania',
  'voivodeship': 'Województwo',
  'county': 'Powiat',
  'commune': 'Gmina',
  'nip': 'NIP',
  'name': 'Nazwa',
  'privateEmail': 'Prywatny e-mail',
  'password': 'Hasło',
  'description': 'Opis',
  'module': 'Moduł',
  'group': 'Grupa',
  'path': 'Typ ścieżki',
  'type': 'Typ szkolenia',
  'validity': 'Ważność szkolenia',
  'place': 'Miejsce',
  'accesses': 'Uprawnienia do zaliczania',
  'is_required': 'Szkolenie obowiązkowe',
  'duration': 'Czas trwania',
  'capacity': 'Miejsce',
  'day_limit': 'Czas na wykonanie',
  'e_learning_external_id': 'ID e-learning',
  'dates': 'Data szkolenia',
  'stages': 'Etapy',
  'coach': 'Trener',
  'modules': 'Moduły',
  'groups': 'Grupy',
  'paths': 'Ścieżki',
  'date_from': 'Data od',
  'date_to': 'Data do',
  'trainer': 'Trener',
};
