import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpStatusCode,
} from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@services/security/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private readonly router: Router,
        private readonly authService: AuthService,
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        if (
            request.url.includes('login') ||
            request.url.includes('register') ||
            request.url.includes('password') ||
            request.url.includes('push-token')
        ) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError(async (error: any, caught: Observable<any>) => {
                if (error.status === HttpStatusCode.Unauthorized) {
                    this.authService.clearStorage();
                    await this.router.navigateByUrl('/logowanie');

                    return of(error);
                }

                throw error;
            }),
        );
    }
}
