import { Component, OnInit } from '@angular/core';
import { GlobalStorageService } from '@services/storages/global-storage.service';
import { NativeService } from '@services/native.service';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
    constructor(
        private readonly globalStorage: GlobalStorageService,
        private readonly nativeService: NativeService,
    ) {}

    ngOnInit() {
        this.globalStorage.initialize();
        this.nativeService.requestFirebaseTokenRefresh();
    }
}
