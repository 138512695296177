import { DICTIONARY_ERROR } from '../const/dictionary-error.const';

export function transformErrorHelper(errResp: any) {
  let errors: any | string = '';

  //if native xhr response
  if (!errResp.error && errResp.response) {
    errResp.error = JSON.parse(errResp.response);
  }

  let jsonBody = errResp.error || errResp;

  if (jsonBody.hasOwnProperty('errors')) {
    let errorsApi: any = jsonBody.errors;

    if (errorsApi['errors']) {
      errorsApi.errors.forEach((error: any) => {
        errors += error + '<br/>';
      })
    } else {
      if (typeof errorsApi === 'object') {
        for (const key in errorsApi) {
          if (errorsApi[key].hasOwnProperty('errors')) {
            if (typeof errorsApi[key]['errors'] === 'object') {
              for (const keyChild in errorsApi[key]['errors']) {
                errors += (dictionaryError[key] || key) + ': ' + errorsApi[key]['errors'][keyChild] + '<br/>';
              }
            } else {
              if (Array.isArray(errorsApi[key]['errors'])) {
                errorsApi[key]['errors'].forEach((k: any) => {
                  errors += (dictionaryError[key] || key) + ': ' + errorsApi[key]['errors'][k] + '<br/>';
                })
              }
            }
          }
        }
      }
    }
  }

  if (jsonBody.hasOwnProperty('errors') && jsonBody['errors'].hasOwnProperty('children')) {
    errors += getErrorFromChildren(jsonBody.errors.children);
  }

  if (jsonBody.hasOwnProperty('message')) {
    if (errors.length == 0) {
      errors += jsonBody.message;
    }
  }

  return errors;
}

function getErrorFromChildren(children: any) {
  let errors = '';

  for (let key in children) {
    const item = children[key];

    if (item.hasOwnProperty('errors')) {
      if (typeof item['errors'] === 'string') {
        const error = item['errors'];
        errors = appendError(errors, error, key);
      } else {
        item.errors.forEach((error: any) => {
          errors = appendError(errors, error, key);
        })
      }
    } else if (item.hasOwnProperty('children')) {
      errors += getErrorFromChildren(item.children)
    }
  }

  return errors;
}

function appendError(errors: any, error: any, key: any) {
  if (DICTIONARY_ERROR[key]) {
    errors += (DICTIONARY_ERROR[key] || key) + ': ';
  }
  errors += error + '<br/>';

  return errors;
}

export const dictionaryError: any = {
  first_name: 'Imię',
  last_name: 'Nazwisko',
  telephone: 'Telefon',
  zip_code: 'Kod pocztowy',
  city: 'Miasto',
  branch: 'Oddział',
  street: 'Ulica',
  old_password: 'Aktualne hasło',
  new_password: 'Nowe hasło',
  locale_number: 'Numer domu i mieszkania',
  email: 'E-mail',
  firstName: 'Imię',
  lastName: 'Nazwisko',
  pesel: 'Pesel',
  phone: 'Nr telefonu',
  bankAccount: 'Nr konta bankowego',
  postCode: 'Kod pocztowy',
  homeNumber: 'Nr domu',
  flatNumber: 'Nr mieszkania',
  voivodeship: 'Województwo',
  county: 'Powiat',
  commune: 'Gmina',
  nip: 'NIP',
  name: 'Nazwa firmy',
  privateEmail: 'Prywatny e-mail',
  password: 'Hasło',
};
