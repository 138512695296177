import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UserModel } from "@shared/models/user.model";
import { ProfileHttpService } from "../../../profile/services/profile-http.service";

@Component({
  selector: 'app-recommendation-certificate',
  templateUrl: './recommendation-certificate.component.html',
  styleUrls: ['./recommendation-certificate.component.scss']
})
export class RecommendationCertificateComponent implements OnInit {
  isUserCertificate!: boolean;
  isUserHasPerformerProfile: boolean;
  isLoading = true;

  constructor(
    private router: Router,
    private profileService: ProfileHttpService,
  ) { }

  ngOnInit() {
    this.profileService.getProfile().subscribe((user: UserModel) => {
      this.isUserCertificate = user.profile_status_int === 3;
      this.isUserHasPerformerProfile = user.has_performer_profile;
      this.checkProfile();
    });
  }

  checkProfile() {
    if (this.isUserHasPerformerProfile) {
      this.goToProfileSettings();
    } else {
      this.isLoading = false;
    }
  }

  go() {
    if (this.isUserCertificate) {
      this.goToProfileSettings();
    } else {
      this.backToProfile();
    }
  }

  backToProfile() {
    this.router.navigateByUrl('/profil');
  }

  goToProfileSettings() {
    this.router.navigate(['recommendation']);
  }
}
