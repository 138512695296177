import { Component, OnInit } from '@angular/core';
import { messagesInThread } from '../../mocks/messagesInThread.mock';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagesHttpService } from '../../services/messages-http.service';

@Component({
    selector: 'app-message-new',
    templateUrl: './message-new.component.html',
    styleUrls: ['./message-new.component.scss'],
})
export class MessageNew {
    title = '';
    message = '';
    protected readonly messagesInThread = messagesInThread;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private messagesHttpService: MessagesHttpService,
    ) {}

    newThread() {
        this.messagesHttpService
            .postNewThread({
                title: this.title,
                message: this.message,
            })
            .subscribe(
                () => {
                    this.navigateBackToThread();
                },
                () => {},
            );
    }
    navigateBackToThread() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
