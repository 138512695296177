<app-previous-page-navigator
    [prevRoute]="'../'"
    [title]="'Wiadomości'"></app-previous-page-navigator>
<div class="content">
  <ng-container *ngFor="let message of messagesInThread?.messages; let index = index">
    <div
      class="message"
      [class.admin]="message.type === 1"
      [class.user]="message.type === 2">
      <div class="date">
        {{ message.created_at }}
      </div>
      <div class="subject" *ngIf="index === 0">
        {{ messagesInThread?.title }}
      </div>
      <div class="text">
        {{ message.message }}
      </div>
    </div>
    <div class="separator list"></div>
  </ng-container>
</div>
<fa-icon
    class="fa-user"
    [icon]="faReply"
    style="color: white"
    (click)="navigateToResponse(threadId)"></fa-icon>
