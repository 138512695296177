import { StoragePersisterInterface } from './storage-persister.interface';

export class StoragePersisterWeb implements StoragePersisterInterface {
    // private storage!: Storage;

    constructor(private readonly storage: Storage) {}

    public hasData(key: string): boolean {
        return null !== this.storage.getItem(key);
    }

    public getData(key: string): string {
        const val = this.storage.getItem(key);

        if (null === val) {
            throw new Error('key does not exists');
        }

        return val;
    }

    public saveData(key: string, value: string): void {
        if (this.hasData(key)) {
            throw new Error('key already exists');
        }

        this.storage.setItem(key, value);
    }

    public removeData(key: string): void {
        if (!this.hasData(key)) {
            throw new Error('key does not exists');
        }

        this.storage.removeItem(key);
    }
}
