<app-previous-page-navigator [prevRoute]="'/szkolenia'" [title]="'szkolenia'">
</app-previous-page-navigator>
<div class="content">
    <div class="trainings-container">
        <div class="separator"></div>
        <ng-container *ngFor="let training of trainingList">
            <div class="training">
                <div class="description" (click)="redirectToTrainingUrl(training)">
                    <div class="text">{{ training.name }}</div>
                    <div class="place-date">
                        <div class="place">{{ training.city }}</div>
                        <div class="vertical">|</div>
                        <div class="date">{{ training.date }}</div>
                    </div>
                </div>
              <div class="calendar" *ngIf="!training.is_past">
                <fa-icon [icon]="faCalendarCirclePlus" style="color: black" (click)="addTrainingToCalendar(training)"></fa-icon>
              </div>
            </div>
            <div class="separator bottom"></div>
        </ng-container>
    </div>
    <div class="navigation">
        <fa-icon [icon]="faChevronsLeft" style="color: black" (click)="firstPage()"></fa-icon>
        <fa-icon [icon]="faChevronLeft" style="color: black" (click)="prevPage()"></fa-icon>
        <div class="page-number">{{pageNumber}}</div>
        <fa-icon [icon]="faChevronRight" style="color: black" (click)="nextPage()"></fa-icon>
        <fa-icon [icon]="faChevronsRight" style="color: black" (click)="lastPage()"></fa-icon>
    </div>
</div>
