<app-previous-page-navigator
  [prevRoute]="'/recommendation'"
  [title]="'Rekomendacje'"
></app-previous-page-navigator>

<div
  [appFullHeightPage]="{hasNavigation: false}"
  class="container sp-con-combo"
  [appLoading]="isLoading"
>
  <section class="recommendation-details">
    <ng-container *ngIf="!isLoading && recImages">
      <div class="description">
        Podaj numer telefonu i/lub adres e-mail inwestora, a my wyślemy mu zaproszenie do dodania opinii o realizacji.
        Inwestor otrzyma informację o adresie realizacji, której dotyczy prośba, Twoim imieniu i nazwisku oraz zdjęcia
        realizacji. Upewnij się, że możesz wykorzystać dodane zdjęcia, dodając je oświadczasz, że dysponujesz prawami
        autorskimi lub posiadasz zgodę na opublikowanie zdjęć w serwisie superwykonawca.pl.
      </div>

      <form *ngIf="form">
        <app-form-control
          [controlName]="'phone_number'"
          [form]="form"
          [id]="'phoneNumber'"
          [inputInputMask]="phoneNumberInputMask"
          [label]="'Numer telefonu inwestora'"
          [placeholder]="'000-000-000'"
          [type]="'tel'"
        ></app-form-control>

        <app-form-control
          [form]="form"
          [controlName]="'email'"
          [id]="'myEmail'"
          [label]="'Adres e-mail inwestora'"
        ></app-form-control>

        <div class="upload-photo">
          <div class="profile-implementations-details-photos">
            <div class="profile-data-entry">
              <div class="label label-pic">Dołączone zdjęcia (wymagane min. 2)</div>
              <div class="implementation-images">
                <div
                  *ngFor="let image of recImages, let idx = index"
                  class="image"
                >
                  <button (click)="removePhoto(idx)">
                    <fa-icon [icon]="removeImageIcon"></fa-icon>
                  </button>
                  <img
                    alt="{{ image.realization_image_id }}"
                    class="image-img"
                    src="{{ image.url }}"
                  />
                </div>

                <div
                  *ngFor="let image of newImages; let i = index"
                  class="image"
                >
                  <button (click)="removePhoto(i, true)">
                    <fa-icon [icon]="removeImageIcon"></fa-icon>
                  </button>
                  <img
                    alt=""
                    class="image-img"
                    src="{{ image }}"/>
                </div>

                <div
                  (click)="fileUpload.click()"
                  *ngIf="(recImages.length + newImages.length) < 6"
                  class="image image-add-more"
                >
                  <fa-icon [icon]="addImageIcon"></fa-icon>

                  <input
                    #fileUpload
                    (change)="onFileSelected($event)"
                    accept="image/png,image/jpg"
                    class="upload-photo-input"
                    type="file"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>

    <p class="text-errors fs-14 lh-16" *ngIf="!isLoading && errorMessage" [innerHTML]="errorMessage"></p>
  </section>

  <button
    *ngIf="!isLoading && form"
    (click)="onSubmit()"
    [disabled]="(recImages.length + newImages.length) < 2 || form?.invalid"
    class="btn btn-primary btn-lg submit"
  >
    Wyślij zaproszenie
  </button>
</div>

