import { Injectable } from '@angular/core';
import { ApiHttpService } from "@services/http/api-http.service";
import { Observable } from "rxjs";
import {
  PerformerRatingDetails,
  ProfileModel,
  RecommendationModel,
  UpdateProfileModel
} from "../models/performer-profile.model";

@Injectable({
  providedIn: 'root'
})
export class RecommendationHttpService {

  constructor(
    private readonly apiService: ApiHttpService
  ) {}

  public uploadAvatar(payload: FormData): Observable<void> {
    return this.apiService.post(`performer-rating/me/upload-avatar`, payload);
  }

  public getPerformerRating(): Observable<ProfileModel> {
    return this.apiService.get('performer-rating/me');
  }

  public getPerformerRatingDetails(performer_rating_id: number): Observable<PerformerRatingDetails> {
    return this.apiService.get(`performer-rating/performer/${performer_rating_id}`);
  }

  public updatePerformerRating(payload: UpdateProfileModel): Observable<void> {
    return this.apiService.put('performer-rating/me', payload);
  }

  public verifyProfile(): Observable<void> {
    return this.apiService.post(`performer-rating/me`);
  }

  public getRealizations(): Observable<RecommendationModel[]> {
    return this.apiService.get('performer-rating/realization/me');
  }

  public sendOpinionRequest(realization_id: number, payload: FormData): Observable<void> {
    return this.apiService.post(`performer-rating/realization-opinion/realization/${realization_id}`, payload);
  }

  public retryEmail(): Observable<void> {
    return this.apiService.post(`performer-rating/email/retry`);
  }

  public retryPhoneNo(): Observable<void> {
    return this.apiService.post(`performer-rating/phone-number/retry`);
  }

  public changePhoneNo(phone: string, code: string): Observable<void> {
    return this.apiService.post(`performer-rating/phone-number/confirm/phone-number/${phone}/code/${code}`);
  }
}
