import { NgModule, SecurityContext } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputMaskModule } from '@ngneat/input-mask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { PreviousPageNavigatorComponent } from './components/previous-page-navigator/previous-page-navigator.component';
import { FormControlComponent } from './components/forms/form-control/form-control.component';
import { FormCheckComponent } from './components/forms/form-check/form-check.component';
import { FormInputComponent } from './components/forms/form-input/form-input.component';
import { FormLabelComponent } from './components/forms/form-label/form-label.component';
import { MaterialModule } from '@shared/material.module';
import { RealizationCreateFloatingComponent } from '@shared/components/realization-create-floating/realization-create-floating.component';
import { FormTextComponent } from './components/forms/form-text/form-text.component';
import { DownloadListItemComponent } from '@shared/components/download-list-item/download-list-item.component';
import { CurrencyAmountComponent } from '@shared/components/currency-amount/currency-amount.component';
import { LoadingDirective } from '@shared/components/loading/loading.directive';
import { FormatNumberPipe } from '@shared/pipes/format-number.pipe';
import { MarkdownModule } from 'ngx-markdown';
import { HttpClient } from '@angular/common/http';
import { BodyBackgroundDirective } from '@shared/directives/body-background.directive';
import { FullHeightPageDirective } from '@shared/directives/full-height-page.directive';
import { ErrorDialogComponent } from '@shared/components/error-dialog/error-dialog.component';

@NgModule({
    declarations: [
        PreviousPageNavigatorComponent,
        FormControlComponent,
        FormCheckComponent,
        FormInputComponent,
        FormLabelComponent,
        RealizationCreateFloatingComponent,
        FormTextComponent,
        DownloadListItemComponent,
        CurrencyAmountComponent,
        LoadingDirective,
        FormatNumberPipe,
        BodyBackgroundDirective,
        FullHeightPageDirective,
        ErrorDialogComponent,
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        InputMaskModule,
        InfiniteScrollModule,
        MaterialModule,
        MarkdownModule.forRoot({
            loader: HttpClient,
            sanitize: SecurityContext.NONE,
        }),
    ],
    exports: [
        CommonModule,
        FontAwesomeModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        InputMaskModule,
        InfiniteScrollModule,
        MaterialModule,
        PreviousPageNavigatorComponent,
        FormControlComponent,
        FormCheckComponent,
        FormInputComponent,
        FormLabelComponent,
        RealizationCreateFloatingComponent,
        DownloadListItemComponent,
        CurrencyAmountComponent,
        LoadingDirective,
        FormatNumberPipe,
        MarkdownModule,
        BodyBackgroundDirective,
        FullHeightPageDirective,
    ],
})
export class SharedModule {}
