import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

export interface FullHeightPageDirectiveOptions {
    hasNavigation: boolean; // previous page navigation, height: 48px
    isInSpLayout: boolean; // padding-top: 30px & padding-bottom: 34px
    isInSpLayoutAlt: boolean; // padding-top: 20px & padding-bottom: 34px
    additionalValue?: number;
}

@Directive({
    selector: '[appFullHeightPage]',
})
export class FullHeightPageDirective implements OnInit {
    @Input() appFullHeightPage?: Partial<FullHeightPageDirectiveOptions>;

    private readonly defaultOptions: FullHeightPageDirectiveOptions = {
        hasNavigation: true,
        isInSpLayout: false,
        isInSpLayoutAlt: false,
    };

    constructor(private renderer: Renderer2, private targetEl: ElementRef) {}

    public ngOnInit(): void {
        const options = this.options;

        const height =
            48 + // main-menu
            (options.hasNavigation ? 48 : 0) +
            (options.isInSpLayout ? 64 : 0) +
            (options.isInSpLayoutAlt ? 54 : 0) +
            (this.options.additionalValue ?? 0);

        const styles = {
            display: 'flex',
            'min-height': `calc(100vh - ${height}px)`,
            'flex-direction': 'column',
            'justify-content': 'space-between',
        };

        for (const [style, value] of Object.entries(styles)) {
            this.renderer.setStyle(this.targetEl.nativeElement, style, value);
        }
    }

    private get options(): FullHeightPageDirectiveOptions {
        return { ...this.defaultOptions, ...this.appFullHeightPage };
    }
}
