import { Injectable } from '@angular/core';
import { ApiHttpService } from '@services/http/api-http.service';
import { Observable } from 'rxjs';
import {
    ImplementationDetailsModel,
    ImplementationDraftModel,
    ImplementationDraftResponseModel,
    ImplementationImageModel,
    UpdateImplementationDraftModel,
} from '@shared/models/implementation.model';

@Injectable({
  providedIn: 'root'
})
export class NewImplementationHttpService {
    constructor(private readonly apiService: ApiHttpService) {}

    public createDraft(
        payload: ImplementationDraftModel,
    ): Observable<ImplementationDraftResponseModel> {
        return this.apiService.post('realization', payload);
    }

    public getDraft(id: number): Observable<ImplementationDetailsModel> {
        return this.apiService.get(`realization/realization_id/${id}`);
    }

    public updateDraft(
        id: number,
        payload: Partial<UpdateImplementationDraftModel>,
    ): Observable<void> {
        return this.apiService.patch(
            `realization/realization_id/${id}`,
            payload,
        );
    }

    public submitDraft(id: number): Observable<ImplementationDraftModel> {
        return this.apiService.post(`realization/realization_id/${id}`);
    }

    public deleteDraft(id: number): Observable<ImplementationDraftModel> {
        return this.apiService.delete(`realization/realization_id/${id}`);
    }

    public uploadImplementationImage(
        id: number,
        payload: FormData,
    ): Observable<ImplementationImageModel> {
        return this.apiService.post(
            `realization-image/realization/realization_id/${id}`,
            payload,
        );
    }

    public deleteImplementationImage(imageId: number): Observable<void> {
        return this.apiService.delete(
            `realization-image/realization_image_id/${imageId}`,
        );
    }
}
