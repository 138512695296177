import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecommendationComponent } from './pages/recommendation/recommendation.component';
import { RouterModule } from "@angular/router";
import { RecommendationProfileEditComponent } from './pages/recommendation-profile-edit/recommendation-profile-edit.component';
import { RecommendationDetailsComponent } from "./pages/recommendation-details/recommendation-details.component";
import { RecommendationSendComponent } from './pages/recommendation-send/recommendation-send.component';
import { RecommendationRouting } from "./recommendation.routing";
import { RecommendationProfileComponent } from "./pages/recommendation-profile/recommendation-profile.component";
import { SelfComponent } from './self/self/self.component';
import { SharedModule } from "@shared/shared.module";
import { RecommendationCertificateComponent } from './pages/recommendation-certificate/recommendation-certificate.component';
import { RecommendationPhoneVerifyComponent } from './pages/recommendation-phone-verify/recommendation-phone-verify.component';


@NgModule({
  declarations: [
    RecommendationComponent,
    RecommendationProfileComponent,
    RecommendationProfileEditComponent,
    RecommendationDetailsComponent,
    RecommendationSendComponent,
    SelfComponent,
    RecommendationCertificateComponent,
    RecommendationPhoneVerifyComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    RecommendationRouting,
    SharedModule,
  ]
})
export class RecommendationModule { }
