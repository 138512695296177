<app-previous-page-navigator
  [prevRoute]="'/profil'"
  [title]="'Rekomendacje'"
></app-previous-page-navigator>

<div
  [appFullHeightPage]="{hasNavigation: false}"
  [appLoading]="isLoading"
  class="container sp-con-outer"
>
  <section class="recommendation">
    <ng-container *ngIf="!isLoading">
      <div class="description">
        Kliknij na zaakceptowaną realizację poniżej, aby wysłać do inwestora prośbę o opinię.
        Od momentu pozyskania pierwszej opinii o realizacji, na stronie superwykonawca.pl będzie widoczny
        Twój profil wykonawcy wraz ze zdjęciem, adresem, telefonem i mailem (jeżeli potwierdziłeś obie formy komunikacji).
        Aby zmienić te dane wejdź w "MOJE DANE SUPERWYKONAWCY" poniżej.
      </div>

      <div
        [routerLink]="['/', 'recommendation', 'profile']"
        class="performer-profile"
      >
        <span>MOJE DANE SUPERWYKONAWCY</span>
        <fa-icon [icon]="iconNext"></fa-icon>
      </div>

      <div *ngIf="disableRecs" class="text-errors fs-14 lh-16">
        Prosimy zweryfikowanie numeru telefonu i/lub adresu e-mail, aby kontynuować.
      </div>

      <ng-container *ngFor="let recommendation of recommendations">
        <div
          (click)="seeDetails(recommendation)"
          [class.disable]="disableRecs"
          class="recommendation-item"
        >
          <div
            *ngIf="recommendation.statusColor"
            [ngClass]="recommendation.statusColor"
            class="tab-status"
          ></div>

          <div class="box">
            <div class="status">{{ recommendation.status }}</div>
            <div class="details">
              <span>{{ recommendation.date | date: 'dd-MM-yyyy' }}</span>
              <span>{{ recommendation.street }}</span>
              <span>{{ recommendation.post_code }} {{ recommendation.city }}</span>
            </div>
          </div>
          <fa-icon *ngIf="recommendation.status == RecommendationStatus.REQUEST" [icon]="iconNext"></fa-icon>
        </div>
      </ng-container>
      <ng-container *ngIf="recommendations.length === 0">
        <div class="recommendation-message">Brak rekomendacji!</div>
      </ng-container>
    </ng-container>
  </section>
</div>

