import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges,
    ViewContainerRef,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faSpinner, IconDefinition } from '@fortawesome/pro-solid-svg-icons';

@Directive({
    selector: '[appLoading]',
})
export class LoadingDirective implements OnInit, OnChanges {
    @Input() appLoading: boolean = false;
    public spinIcon: IconDefinition;

    constructor(
        private renderer: Renderer2,
        private targetEl: ElementRef,
        private viewContainerRef: ViewContainerRef,
    ) {
        this.spinIcon = faSpinner;
    }

    public ngOnInit(): void {
        this.targetEl.nativeElement.classList.add(
            'app-loading-relative-wrapper',
        );

        const loadingContainer = this.renderer.createElement('div');
        this.renderer.addClass(loadingContainer, 'app-loading');

        if (this.appLoading) {
            // this.renderer.addClass(loadingContainer, 'is-loading');
            this.targetEl.nativeElement.classList.add('is-loading');
        }

        const iconComponent =
            this.viewContainerRef.createComponent<FaIconComponent>(
                FaIconComponent,
            );
        iconComponent.instance.icon = this.spinIcon;
        iconComponent.instance.spin = true;
        iconComponent.instance.ngOnChanges({}); // to force render icon

        this.renderer.appendChild(
            this.targetEl.nativeElement,
            loadingContainer,
        );

        this.renderer.appendChild(
            loadingContainer,
            iconComponent.location.nativeElement,
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['appLoading']) {
            const loadingContainer = this.targetEl.nativeElement;

            const loading = loadingContainer.querySelector(
                ':scope > div.app-loading',
            );

            if (loading) {
                if (this.appLoading) {
                    // this.renderer.addClass(loading, 'is-loading');
                    this.renderer.addClass(loadingContainer, 'is-loading');
                } else {
                    // this.renderer.removeClass(loading, 'is-loading');
                    this.renderer.removeClass(loadingContainer, 'is-loading');
                }
            }
        }
    }
}
